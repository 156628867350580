import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import * as React from 'react';
import { cn } from '../../../utils/cn';

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default: 'bg-primary text-primary-foreground hover:bg-primary/90',
				success: 'bg-success text-success-foreground hover:bg-success/90',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
				secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, isLoading, children, ...props }, ref) => {
		if (asChild) {
			return (
				<Slot ref={ref} {...props}>
					<>
						{React.Children.map(children as React.ReactElement, (child: React.ReactElement) => {
							return React.cloneElement(child, {
								className: cn(buttonVariants({ variant, size }), className),
								children: (
									<>
										{isLoading && (
											<Loader2 className={cn('h-4 w-4 animate-spin', children && 'mr-2')} />
										)}
										{child.props.children}
									</>
								),
							});
						})}
					</>
				</Slot>
			);
		}

		return (
			<button
				className={cn(buttonVariants({ variant, size, className }))}
				disabled={isLoading}
				ref={ref}
				{...props}>
				<>
					{isLoading && <Loader2 className={cn('h-4 w-4 animate-spin', children && 'mr-2')} />}
					{children}
				</>
			</button>
		);
	}
);

Button.displayName = 'Button';

export { Button, buttonVariants };
