import { allInterceptors } from '../../api';

export const authenticateApi = (clientId: string, token: string) => {
	allInterceptors.forEach((ax: any) => {
		ax.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		ax.defaults.headers.common['x-gle-client'] = clientId;
	});
};

export const addIdentityIdToApi = (identityId: string) => {
	allInterceptors.forEach((ax: any) => {
		ax.defaults.headers.common['x-gle-role'] = identityId;
	});
};
