import { Badge } from '@/components/badge';
import { Heading } from '@gle/base-ui.typography.heading';
import { useConan } from '@golee/gle-conan-tracker';
import { LinkIcon } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocument } from '../../../api/documents/entities/documents';
import { LoadingLayout } from '../../../components';
import toast from '../../../components/toast/toast';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { OrgPerson } from '../../../core/orgPerson/types';
import { initSignature, saveConsents } from './api';
import { ConsentAnswered } from './consent-answered';
import { ConsentNotAnswered } from './consent-not-answered';
import { Consent, OrgPersonConsent, OrgPersonConsentStatus } from './types';
import { useConsents } from './use-consents';

export const Consents = () => {
	const { orgPerson, refresh: reloadOrgPerson } = useOrgPerson();
	const { consents = [], isLoading: isLoadingConsents = true } = useConsents();

	useEffect(() => {
		return () => {
			//This is because the orgPerson has the consents in his readModel, so we need to refresh it
			reloadOrgPerson();
		};
	}, []);

	if (isLoadingConsents) {
		return <LoadingLayout />;
	}

	return (
		<div className="pb-10">
			<Heading level={3} className="mb-5">
				Consensi
			</Heading>
			{consents.map(consent => (
				<ConsentCard consent={consent} orgPerson={orgPerson} key={consent.consentId} />
			))}
		</div>
	);
};

const ConsentCard = (props: { orgPerson: OrgPerson; consent: Consent }) => {
	const { trackEvent } = useConan();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const _orgPersonConsent = !Array.isArray(props.orgPerson.consents)
		? undefined
		: props.orgPerson.consents?.find(consent => consent.consentId === props.consent.consentId);

	const [orgPersonConsent, setOrgPersonConsent] = useState<OrgPersonConsent | undefined>(_orgPersonConsent);

	const saveConsentResponse = async (status: OrgPersonConsentStatus) => {
		try {
			await saveConsents({
				consents: [
					{
						consentId: props.consent.consentId,
						status,
					},
				],
				season: props.orgPerson.season,
			});

			trackEvent('family.consent.response.set', {
				consent: props.consent.consentLabel,
				status,
			});
		} catch (err) {}
	};

	const initSignatureFlow = async () => {
		setIsLoading(true);

		try {
			const { data } = await initSignature({
				consentId: props.consent.consentId,
				orgPersonId: props.orgPerson.orgPersonId,
			});

			const { data: document } = await getDocument(data.documentId);
			navigate(`/documents/${document._id}`, {
				state: {
					document,
				},
			});
		} catch (err) {
			toast.error(`Si è verificato un errore, riprova più tardi`);
		} finally {
			setIsLoading(false);
		}
	};

	const onUpdateConsent = (status: OrgPersonConsentStatus) => {
		if (status === 'given' && props.consent.withSignature) {
			initSignatureFlow();
			return;
		}

		// Optimistic UI
		setOrgPersonConsent({
			consentId: props.consent.consentId,
			status: status,
			history: [
				...(_orgPersonConsent?.history || []),
				{
					changedAtSeason: props.orgPerson.season,
					change: status as 'given' | 'denied' | 'removed',
					changedAt: moment().toISOString(),
					changedBy: 'org-person',
				},
			],
		});

		void saveConsentResponse(status);
	};

	return (
		<div className="border-b border-solid border-slate-200 py-4">
			<h3 className="text-lg font-medium">{props.consent.consentLabel}</h3>
			<p className="text-sm text-slate-500">{props.consent.consentText}</p>

			{props.consent.withSignature && !orgPersonConsent?.signatureId && (
				<Badge className="my-1" variant={'warning'}>
					Da firmare
				</Badge>
			)}

			{props.consent.consentUrl && (
				<a
					href={props.consent.consentUrl}
					target={'_blank'}
					className="mt-2 flex cursor-pointer items-center gap-1 text-sm font-medium text-slate-500 underline">
					Vedi allegato <LinkIcon size={16} />
				</a>
			)}

			<div className="mt-4">
				{!orgPersonConsent?.status ? (
					<ConsentNotAnswered onChange={onUpdateConsent} isLoading={isLoading} />
				) : (
					<ConsentAnswered
						orgPersonConsent={orgPersonConsent}
						onEdit={() => setOrgPersonConsent(undefined)}
					/>
				)}
			</div>
		</div>
	);
};
