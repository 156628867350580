import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { DownloadIcon } from 'lucide-react';

export const InvoiceDownloadButton = ({ invoiceId }: { invoiceId: string }) => {
	const { messages } = useI18n();
	const { trackEvent } = useConan();

	return (
		<a
			href={`${window._env_.REACT_APP_API_URL_FINANCIAL_SERVICE}invoices/${invoiceId}/pdf-preview`}
			target={'_blank'}>
			<Button variant={'secondary'} onClick={() => trackEvent('family.invoice-opened')}>
				{messages['invoice']} <DownloadIcon size={18} className="ml-2" />
			</Button>
		</a>
	);
};
