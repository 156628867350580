import { Form, Formik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { OrgPerson } from '../../core/orgPerson/types';
import { useCustomFields } from '../../hooks/CustomInputFields';
import { useMetadata } from '../../hooks/use-metadata';
import { CustomInputFields } from '../custom-fields/custom-input-fields';
import { SkeletonParagraph } from '../skeleton';

export const MetadataCustomFieldsForm = ({ orgPerson, isEditing }: { orgPerson: OrgPerson; isEditing: boolean }) => {
	const { customFields } = useCustomFields();
	const { data, set, isLoading } = useMetadata({
		orgPersonId: orgPerson.orgPersonId,
		season: orgPerson.season,
	});
	const isSaving = useRef<boolean>(false);

	//TODO: refactor this
	if (isLoading) {
		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
					<SkeletonParagraph style={{ height: 30, marginBottom: 10 }} />
				</div>
			</>
		);
	}

	//TODO make dynamic validation schema based on custom fields
	const schema = Yup.object().shape({
		athlete: Yup.object().shape({
			technicalProfile: Yup.object().shape({
				'foot-n': Yup.number(),
			}),
		}),
	});

	return (
		<>
			<Formik initialValues={data} validationSchema={schema} enableReinitialize onSubmit={values => set(values)}>
				{formContext => {
					if (formContext.dirty && !isSaving.current) {
						isSaving.current = true;
						formContext.submitForm().then(() => {
							isSaving.current = false;
						});
					}
					return (
						<Form>
							<CustomInputFields fields={customFields} formContext={formContext} isEditing={isEditing} />
						</Form>
					);
				}}
			</Formik>
		</>
	);
};
