import { Button } from '@/components/button';
import { TInvite } from '../../api/people/entities/invites';
import { useProfiles } from '../../context/profiles/use-profiles';

export const InviteCard = ({ invite }: { invite: TInvite }) => {
	const { acceptInvite } = useProfiles();

	return (
		<div className="flex items-center justify-between border-b border-solid border-gray-100 py-5">
			<div className="flex items-center gap-2">
				<p>
					{invite.name} {invite.surname}
				</p>
			</div>
			<Button onClick={() => acceptInvite(invite.invite.inviteId)}>Accetta</Button>
		</div>
	);
};
