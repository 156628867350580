import moment from 'moment';
import { useEffect, useState } from 'react';
import { getEventsByParticipant, getStatisticsByParticipant } from './api';
import { CalendarEvent } from './types';

export type ParticipantStatistics = {
	eventsCount: number;
	terminatedEventsCount: number;
	presences: {
		trueCount: number;
		falseCount: number;
		unansweredCount: number
	};
};

export const useCalendarEvents = (orgPersonId: string, organizationId: string) => {
	const [events, setEvents] = useState<CalendarEvent[]>([]);
	const [statistics, setStatistics] = useState<ParticipantStatistics>();
	const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

	const [skip, setSkip] = useState<number>(0)
	const limit = 10;

	// const [week, setWeek] = useState<{ start: Moment; end: Moment }>({
	// 	start: moment().startOf('week').startOf('day'),
	// 	end: moment().endOf('week').endOf('day'),
	// });

	// const onPrevWeek = (options?: { prefetch?: boolean }) => {
	// 	const start = week.start.clone().subtract(1, 'week');
	// 	const end = week.end.clone().subtract(1, 'week');

	// 	setWeek({
	// 		start,
	// 		end,
	// 	});

	// 	void calcWeekEvents(start, end, options);
	// };

	// const onNextWeek = (options?: { prefetch?: boolean }) => {
	// 	const start = week.start.clone().add(1, 'week');
	// 	const end = week.end.clone().add(1, 'week');

	// 	setWeek({
	// 		start,
	// 		end,
	// 	});

	// 	void calcWeekEvents(start, end, options);
	// };

	// const calcWeekEvents = async (start: Moment, end: Moment, options?: { prefetch?: boolean }) => {
	// 	try {
	// 		const { data } = await getEventsByParticipant(orgPersonId, start.toISOString(), end.toISOString());

	// 		if (!options?.prefetch) {
	// 			setEvents(data);
	// 		}
	// 	} catch (err) { }
	// };

	const upcomingEvents = async () => {
		const { data } = await getEventsByParticipant(orgPersonId, organizationId, { skip, limit, fromDate: moment().startOf('d').toISOString() });
		setEvents([...events, ...data])
		setSkip(skip + limit)
		setCanLoadMore(data.length === limit)
	}

	const loadStatistics = async (
		partipantId: string,
		params: { fromDate?: string; toDate?: string; date?: string; type?: string } = {}
	) => {
		try {
			const { data } = await getStatisticsByParticipant(partipantId, params);
			setStatistics(data);
		} catch (err) {
		}
	};

	useEffect(() => {

	}, []);

	return {
		// week,
		events,
		// onPrevWeek,
		// onNextWeek,
		skip,
		canLoadMore,
		// limit,
		upcomingEvents,
		loadStatistics,
		statistics,
	};
};
