import axios from 'axios';

type ApiService =
	| 'bayes'
	| 'club'
	| 'documents'
	| 'exports'
	| 'financial'
	| 'guardian'
	| 'hermes'
	| 'moleskine'
	| 'payments'
	| 'pdf-kompiler'
	| 'people';

const localConfig: { [key in ApiService]?: { port: number } } = {
	// bayes: {
	// 	port: 4000,
	// },
	// people: {
	//     port: 3002,
	// },
	// payments: {
	// 	port: 4000,
	// },
	// club: {
	// 	port: 4000,
	// },
	// financial: {
	//     port: 4000,
	// },
	// 'pdf-kompiler': {
	// 	port: 4001,
	// },
	// hermes: {
	// 	port: 4000
	// }
	// documents: {
	// 	port: 4000,
	// },
};

export const uniqueAxiosInstance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

export const apiClient = (service: ApiService) => {
	let baseURL = `${window._env_.REACT_APP_API_BASE_URL}${service}`;

	if (window._env_.REACT_APP_NAME_ENV === 'local') {
		if (localConfig[service]) {
			baseURL = `http://localhost:${localConfig[service]?.port}`;
		}
	}

	uniqueAxiosInstance.defaults.baseURL = baseURL;

	return uniqueAxiosInstance;
};
