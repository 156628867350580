import { isValidDate } from '@gle/base-ui.inputs.input-date';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Metadata } from '../../../../../api/people/entities/metadata';
import { InputField } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import toast from '../../../../../components/toast/toast';
import { Flex } from '../../../../../core/@ui/flex';
import { InputDateField } from '../../../../../core/inputs/DatePicker';
import { MetadataForm } from '../../../../../core/metadata';
import { sendMemberChangedDataNotification, translationsMap } from '../../utils';
import { FormProps } from '../types';

export const OtherForm = (props: FormProps) => {
	const { messages } = useI18n();
	const { trackEvent } = useConan();

	const onSubmit = async (
		values: Metadata,
		set: any,
		formTouchedValues?: { dates?: {}; identityDocument?: {}; academics?: {}; iban?: boolean }
	) => {
		await set(
			values,
			() => {
				toast.error(messages['save_error']);
			},
			() => {
				toast.success(messages['save_successful']);
			}
		);
		const touchedValues = [];

		if (formTouchedValues?.dates) {
			for (const value in formTouchedValues.dates) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		if (formTouchedValues?.identityDocument) {
			for (const value in formTouchedValues.identityDocument) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		if (formTouchedValues?.academics) {
			for (const value in formTouchedValues.academics) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		if (formTouchedValues?.iban) {
			touchedValues.push(translationsMap.get('iban'));
		}
		props.onSave();
		props.onChangeEdit(false);
		if (touchedValues.length) {
			sendMemberChangedDataNotification(props.orgPerson, touchedValues as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: touchedValues.join(', '),
				section: 'other',
			});
		}
	};
	const validationSchema = yup.object().shape({
		person: yup.object().shape({
			// dates: metadataDatesValidationSchema(messages),
		}),
	});
	return (
		<>
			<div>
				<MetadataForm orgPersonId={props.orgPerson?.orgPersonId} season={props.orgPerson.season}>
					{({ data, set, isSaving }) => {
						return (
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={data}
								onSubmit={() => {}}>
								{formContext => {
									return (
										<Form>
											<SubmitButtons
												formContext={formContext}
												isSaving={isSaving}
												isEditing={props.isEditing}
												onChangeEdit={value => props.onChangeEdit(value)}
												onSubmit={() =>
													onSubmit(
														formContext.values,
														set,
														formContext.dirty
															? {
																	//@ts-ignore
																	dates: formContext.touched?.person?.dates,
																	//@ts-ignore
																	academics: formContext.touched?.person?.academics,
																	identityDocument:
																		//@ts-ignore
																		formContext.touched?.person?.identityDocument,
																	//@ts-ignore
																	iban: formContext.touched?.person?.iban,
																}
															: undefined
													)
												}
											/>
											<Flex gap={10} flexDirection="column" marginBlock={10}>
												<InputField
													label={messages['id_document_number']}
													name="person.identityDocument.number"
													autoComplete="off"
												/>
												<InputDateField
													name="person.dates.identityDocumentExpirationDate"
													label={messages['id_document_expiration_date']}
													isClearable
													onChange={(value, formats) => {
														formContext.setFieldValue(
															'person.dates.identityDocumentExpirationDate',
															value && isValidDate(value) ? formats.ISOString : value
														);
													}}
												/>
												<InputDateField
													name="person.identityDocument.releaseDate"
													label={messages['id_document_release_date']}
													isClearable
													onChange={(value, formats) => {
														formContext.setFieldValue(
															'person.identityDocument.releaseDate',
															value && isValidDate(value) ? formats.ISOString : value
														);
													}}
												/>
												<InputField
													label={messages['attended_school']}
													name="person.academics.attendedSchool"
													autoComplete="off"
												/>
												<InputField
													label={messages['school_specialism']}
													name="person.academics.schoolSpecialism"
													autoComplete="off"
												/>
												<InputField
													label={messages['iban']}
													name="person.iban"
													autoComplete="off"
													upperCase
												/>
											</Flex>
										</Form>
									);
								}}
							</Formik>
						);
					}}
				</MetadataForm>
			</div>
		</>
	);
};
