import { toast as toastify, ToastOptions } from 'react-toastify';

const toastStyle = {
	style: {
		borderRadius: 8,
		fontSize: 15,
	},
	hideProgressBar: true,
};

const toast = {
	success: (message: string) => {
		render(ToastType.SUCCESS, message, {
			...toastStyle,
			style: { ...toastStyle.style, backgroundColor: '#effbf1' },
		});
	},

	error: (message: string) => {
		render(ToastType.ERROR, message, { ...toastStyle, style: { ...toastStyle.style, backgroundColor: '#fdecee' } });
	},

	info: (message: string, config?: ToastOptions) => {
		render(ToastType.INFO, message, config);
	},

	warning: (message: string) => {
		render(ToastType.WARNING, message, {
			...toastStyle,
			style: { ...toastStyle.style, backgroundColor: '#fdf4ec' },
		});
	},
};

export default toast;

export enum ToastType {
	ERROR = 'error',
	SUCCESS = 'success',
	WARNING = 'warning',
	INFO = 'info',
	DEFAULT = 'default',
	MODAL = 'modal',
}

const config: ToastOptions = {
	position: 'top-center',
	autoClose: 1000,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
};

export const render = (type: ToastType, title?: string, extraConfig?: ToastOptions) => {
	switch (type) {
		case ToastType.ERROR:
			toastify.error(title || 'Errore', { ...config, ...extraConfig });
			break;

		case ToastType.SUCCESS:
			toastify.success(title || 'Successo', { ...config, ...extraConfig });
			break;

		case ToastType.WARNING:
			toastify.warning(title || 'Attenzione', { ...config, ...extraConfig });
			break;

		case ToastType.INFO:
			toastify.info(title || 'Informazione', { ...config, ...extraConfig });
			break;

		case ToastType.DEFAULT:
			toastify.info(title || 'Attenzione', { ...config, ...extraConfig });
			break;
	}
};
