import { Theme } from '@gle/base-ui.theme-provider';
import { I18nProvider } from '@gle/providers.i18n';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { AppRouter } from './router/app-router';

const App = () => {
	return (
		<Theme className="golee">
			<I18nProvider>
				<BrowserRouter>
					<AppRouter />
				</BrowserRouter>
			</I18nProvider>
		</Theme>
	);
};

export default App;
