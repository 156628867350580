import { OrgPerson } from '../../core/orgPerson/types';
import { Section } from '../section';

export const AddressSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const address = orgPerson.personMetadata.address;

	if (!address) return <Section title="Indirizzo">Non impostato</Section>;

	return (
		<>
			<Section title={'Indirizzo'}>
				{[
					address.address,
					address.num,
					address.cap,
					address.town,
					address.province,
					address.region,
					address.nation,
				]
					.filter(part => !!part)
					.join(', ')}
			</Section>
		</>
	);
};
