import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';
import { useSeason } from '../../context/season';
import { readableSeason } from './readable-season';

export const SeasonSelector = () => {
	const { seasons, selectedSeason, selectSeason } = useSeason();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onSeasonSelected = (season: string) => {
		selectSeason(season);
	};

	if (seasons.length === 1) return <></>;

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger>
				<div className={'flex items-center gap-1 text-slate-500'}>
					<p>{readableSeason(selectedSeason)}</p>
					<ChevronDownIcon size={16} />
				</div>
			</PopoverTrigger>
			<PopoverContent className="max-w-[150px] p-0">
				{seasons.map((season, i) => (
					<div key={season} className="border-b border-solid border-slate-100 p-2">
						<p
							onClick={(e: any) => {
								onSeasonSelected(season);
								setIsOpen(false);
							}}>
							{readableSeason(season)}
						</p>
					</div>
				))}
			</PopoverContent>
		</Popover>

		// <div className={'flex items-center gap-5'} ref={wrapperRef}>
		// 	<div onClick={togglePopup} className="flex cursor-pointer items-center gap-1 rounded p-2 text-gray-500">
		// 		<div className={'flex items-center'}>
		// 			<Text color="gray">{readableSeason(selectedSeason)}</Text>
		// 			<ChevronDownIcon size={16} className="text-gray-500" />
		// 		</div>
		// 	</div>
		// 	{isPopupOpen && (
		// 		<div
		// 			className={`w-min-[80px] absolute right-0 top-7 flex flex-col items-center gap-3 rounded border bg-white p-3 shadow`}>
		// 			{seasons.map((season, i) => (
		// 				<Text
		// 					key={i}
		// 					className={'cursor-pointer'}
		// 					onClick={(e: any) => {
		// 						e.preventDefault();
		// 						setIsPopupOpen(false);
		// 						onSeasonSelected(season);
		// 					}}
		// 					weight={season === selectedSeason ? 'bold' : 'regular'}>
		// 					{readableSeason(season)}
		// 				</Text>
		// 			))}
		// 		</div>
		// 	)}
		// </div>
	);
};
