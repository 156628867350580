import { GroupsSection, MembershipSection } from '../../../../../components';
import { MetadataCustomFieldsForm } from '../../../../../components/org-person-sections/metadata-custom-fields-form';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { ViewProps } from '../types';

export const SportView = (props: ViewProps) => {
	if (!props.orgPerson) return <></>;

	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<GroupsSection orgPerson={props.orgPerson} />
			<MembershipSection orgPerson={props.orgPerson} />
			<MetadataCustomFieldsForm orgPerson={props.orgPerson} isEditing={props.isEditing} />
		</>
	);
};
