import React from 'react';

const format = (string: string, params?: any, replaceLineBreak: boolean = true): string => {
	if (!string) return '';

	if (params) {
		Object.keys(params).forEach((key: string) => {
			var regEx = new RegExp(`{${key}}`, 'g');
			string = string.replace(regEx, params[key]);
		});
	}

	if (replaceLineBreak) {
		string = string.replace(/\n/g, `<br/>`);
	}

	return string;
};

const formatAsString = (string: string, params?: any, replaceLineBreak: boolean = true): string => {
	return format(string, params, replaceLineBreak);
};

const formatAsHTML = (string: string, params?: any): React.ReactNode => {
	return (
		<span
			dangerouslySetInnerHTML={{
				__html: format(string, params),
			}}
		/>
	);
};

export default {
	format,
	formatAsString,
	formatAsHTML,
};
