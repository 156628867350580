import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { formatAsString } from '../../../utils/lang-utility';
import langService from '../languageFlags/services/lang.service';

export const NotCashedTrancheInfo = ({
	tranche,
	suggestGoleePayActivation,
	isLoadingPaymentInfo,
}: {
	tranche: Partial<TObjTranche>;
	suggestGoleePayActivation: boolean;
	isLoadingPaymentInfo: boolean;
}) => {
	const { messages } = useI18n();

	return (
		<>
			{tranche.expected_cashed_date ? (
				<p className="mb-2 text-sm">
					{formatAsString(messages['to_be_paid_within_date'], {
						date: tranche.expected_cashed_date,
					})}
					<br />
					<br />
				</p>
			) : (
				<p className="mb-2 text-sm">Nessun dettaglio presente</p>
			)}

			{!isLoadingPaymentInfo && (
				<>
					{!suggestGoleePayActivation ? (
						<>
							<p className="text-sm">
								{messages['you_can_paid_with_your_preferred_payment_method']} <br />
								<br />
								{messages['payment_will_be_cashed_by_club_message']}
							</p>
						</>
					) : (
						<p style={{ borderTop: '1px solid var(--gray-lightest-color)' }}>
							<br />
							{tranche.type === 'ENT' && (
								<>
									{langService.formatAsHTML(
										messages['invite_your_organization_to_activate_golee_pay']
									)}
								</>
							)}{' '}
						</p>
					)}
				</>
			)}
		</>
	);
};
