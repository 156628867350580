import { apiClient } from '../../../../api/client';
import { CalendarEvent } from '../types';
import { ParticipantStatistics } from '../use-calendar-events';

export const getEventsByParticipant = (
	participantId: string,
	organizationId: string,
	params?: { fromDate?: string; toDate?: string; skip?: number; limit?: number }
) =>
	apiClient('club').get<CalendarEvent[]>(`calendar-events/get-by-participant/${organizationId}/${participantId}`, {
		params,
	});

export const getUnsetEventsByParticipant = ({ participantId }: { participantId: string }) =>
	apiClient('club').get<number>(`calendar-events/get-by-participant/${participantId}/unset-count`);

export const setParticipantPresence = (eventId: string, participantId: string, presence: boolean, note?: string) =>
	apiClient('club').put<void>(`calendar-events/${eventId}/participants/${participantId}/presence`, {
		presence,
		note,
	});
export const getStatisticsByParticipant = (participantId: string, params: any) =>
	apiClient('club').get<ParticipantStatistics>(`calendar-events/participants/${participantId}/statistics`, {
		params,
	});
