import client from '../client';

type Response<T> = {
	data: T;
};

const path = `receipts`;

export type Receipt = {
	id: string;
	receiptNumber: string;
	organizationId: string;
	createdAt: string;
	operations: string[];
	orgPersonId?: string;
	orgPartnerId?: string;
	holderDenomination: string;
	issuedAt: string;
	amount: number;
	type: string;
	season: string;
	deleted: boolean;
	metadata: any;
	documentUrl?: string;
	note?: string;
};

const getReceipt = async (params: { id: string }): Promise<Response<Receipt>> => {
	try {
		const { id } = params;
		const response = await client.get(`${path}/${id}`, {
			// headers: {
			// 	'x-gle-current-season': params.season,
			// 	'x-gle-selected-season': params.season,
			// },
		});

		return {
			...response,
			data: response.data,
		};
	} catch (err) {
		throw err;
	}
};

export { getReceipt };
