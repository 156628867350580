import { Badge } from '@/components/badge';
import { useNavigate } from 'react-router-dom';
import { useSeason } from '../../context/season';
import { useTranches } from '../../context/tranches';
import { readableSeason } from '../season/readable-season';
import { Section } from '../section';

export const PaymentsSection = () => {
	const navigate = useNavigate();
	const { tranches } = useTranches();
	const { selectedSeason } = useSeason();

	if (!tranches.length) return <></>;

	const payed = tranches
		.filter(t => t.cashed && t.type === 'ENT')
		.map(t => t.amount.total_amount)
		.reduce((t1, t2) => t1 + t2, 0);

	const total = tranches
		.filter(tranche => tranche.type === 'ENT')
		.map(t => t.amount.total_amount)
		.reduce((t1, t2) => t1 + t2, 0);

	const diff = total - payed;

	return (
		<Section title={`Situazione Pagamenti ${readableSeason(selectedSeason)}`} onClick={() => navigate('/payments')}>
			<div className={'flex items-center justify-between'}>
				<p>{`€ ${payed} di € ${total}`}</p>

				<Badge variant={diff > 0 ? 'warning' : 'success'} onClick={() => navigate(`/payments/`)}>
					{diff > 0 ? 'Da Pagare' : 'Pagato'}
				</Badge>
			</div>
		</Section>
	);
};
