import { Text } from '@gle/base-ui.typography.text';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sponsor } from '../../api/club/entities/sponsors';

export const Carousel = ({ sponsors }: { sponsors: Sponsor[] }) => {
	const containerSponsorsRef = useRef<HTMLDivElement>(null);
	const lineSponsorsRef = useRef<HTMLDivElement>(null);
	const [scrolling, setScrolling] = useState(false);

	useEffect(() => {
		if (!containerSponsorsRef.current || !lineSponsorsRef.current) return;

		const lineSponsorsWidth = lineSponsorsRef.current.offsetWidth;
		const containerSponsorsWidth = containerSponsorsRef.current.offsetWidth;

		setScrolling(lineSponsorsWidth >= containerSponsorsWidth);
	}, [sponsors]);

	return (
		<div className={'h-[106px]'}>
			{sponsors.length ? (
				<div className={'mt-4 flex flex-col'}>
					<div className={'flex flex-col gap-2'}>
						<HorizontalDivider text={'I nostri sponsor'} />
						<div
							className={'inline-flex w-full flex-nowrap justify-center overflow-hidden'}
							ref={containerSponsorsRef}>
							{!scrolling ? (
								<div ref={lineSponsorsRef} className={'flex justify-center'}>
									<SponsorsList sponsors={sponsors} />
								</div>
							) : (
								<div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_64px,_black_calc(100%-10px),transparent_100%)]">
									<SponsorsList sponsors={sponsors} animate />
									<SponsorsList sponsors={sponsors} animate ariaHidden />
								</div>
							)}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

const SponsorsList = ({
	sponsors,
	animate,
	ariaHidden,
}: {
	sponsors: Sponsor[];
	animate?: boolean;
	ariaHidden?: boolean;
}) => {
	const navigate = useNavigate();

	return (
		<ul
			onClick={() => navigate('/sponsors')}
			className={`${animate && 'animate-infinite-scroll'} scroll flex items-center justify-center [&_img]:max-w-none [&_li]:mx-6`}
			aria-hidden={ariaHidden}>
			{sponsors
				.filter(s => s.logoUrl)
				.map(sponsor => (
					<li className="relative flex h-20 min-w-[80px] cursor-pointer flex-col content-center items-center justify-center gap-1 rounded-lg p-3 hover:border-gray-200 hover:bg-gray-50">
						<img className={'h-12'} src={sponsor.logoUrl} alt={sponsor.name} />
					</li>
				))}
		</ul>
	);
};

const HorizontalDivider = ({ text }: { text?: string }) => {
	return (
		<div className={'flex items-center gap-3'}>
			<div className={'flex h-[1px] flex-grow border-t border-solid border-gray-200'} />
			<Text color={'gray'}>{text}</Text>
			<div className={'flex h-[1px] flex-grow border-t border-solid border-gray-200'} />
		</div>
	);
};
