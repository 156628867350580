import { Link } from '@gle/base-ui.typography.link';
import { Text, TextProps } from '@gle/base-ui.typography.text';
import { ReactNode } from 'react';

type TextWithLinksRendererProps = {
	text: string;
	size?: TextProps['size'];
};

export const TextWithLinksRenderer: React.FC<TextWithLinksRendererProps> = props => {
	const urlRegex = /https?:\/\/[^\s]+/g;

	const content: ReactNode[] = props.text
		.split(urlRegex)
		.reduce((acc: ReactNode[], part: string, index: number): ReactNode[] => {
			const parsedPart = <span dangerouslySetInnerHTML={{ __html: part.replace(/\n/g, '<br />') }}></span>;

			if (index === 0) {
				return [parsedPart];
			}

			const match = (props.text.match(urlRegex) ?? [])[index - 1];

			const linkElement = (
				<Link href={match} size={props.size} target="_blank" rel="noopener noreferrer" color="link" key={index}>
					{match}
				</Link>
			);

			return [...acc, linkElement, parsedPart];
		}, []);

	return <Text size={props.size}>{content}</Text>;
};
