import { useOrgPerson } from '../../../../../context/org-person/use-org-person';
import { Section } from '../../../section';
import { TabProps } from '../types';
import { SportForm } from './form';
import { SportView } from './view';

export const SportTab = (props: TabProps) => {
	const { orgPerson, refresh } = useOrgPerson();

	return (
		<Section
			isEditing={props.isEditing}
			headerText={'Dati Sportivi'}
			viewSection={
				<SportView
					orgPerson={orgPerson}
					isEditing={props.isEditing}
					onChangeEdit={(value: boolean) => props.onChangeEdit(value)}
				/>
			}
			formSection={
				<SportForm
					orgPerson={orgPerson}
					isEditing={props.isEditing}
					onSave={refresh}
					onChangeEdit={(value: boolean) => props.onChangeEdit(value)}
				/>
			}
		/>
	);
};
