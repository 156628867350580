import { ReactNode } from 'react';

const BaseIcon = (props: { dimension: number; children: ReactNode }) => {
	return (
		<svg
			width={props.dimension}
			height={props.dimension}
			viewBox="0 0 140 163"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			{props.children}
		</svg>
	);
};

const FemaleIcon = (props: { dimension: number }) => (
	<BaseIcon dimension={props.dimension}>
		<path d="M34 100.5L39 78.5L51 83.5L52.5 95.5L42 105L34 100.5Z" fill="#2D2123" />
		<path d="M27 163V145.5L4 134V154.817C4.72247 159.054 5.51383 161.325 9.30769 163H27Z" fill="#EDC286" />
		<path d="M112 163V145.5L135 134V154.817C134.278 159.054 133.486 161.325 129.692 163H112Z" fill="#EDC286" />
		<path
			d="M41 99C44.0932 95.6918 44.8407 92.4459 45 85C54.6246 93.4997 59.8548 95.2404 69 95C78.9984 94.6148 84.2827 92.5998 93 85C93.0012 92.7611 94.101 95.6777 97.5 99C87.2118 108.374 80.0432 118.935 68 127.5C56.2808 119.853 51.1547 109.4 41 99Z"
			fill="#EDC286"
		/>
		<path
			d="M98 35C78.0612 40.3524 67.0361 38.1703 47.5 30C36.7617 34.766 33.6207 38.9492 32 48.5V53.5C28.2263 53.2433 26.9503 54.3508 26 58C26.3739 61.9792 26.9326 64.2553 29 68.5C31.0562 73.0512 32.6507 73.5216 36 72C38.4918 77.9663 40.5331 81.0058 45 86C62.7799 101.03 76.9239 100.21 93 86C97.4453 80.8204 99.1768 77.6798 102 72C104.823 73.8189 106.343 72.6182 109 68.5C111.149 64.9845 111.781 62.7171 111.5 58C111.095 54.7267 110.137 53.599 106.5 53.5V48.5L98 35Z"
			fill="#F4D2A3"
		/>
		<path
			d="M29.5 101.5L41.0002 99C52.5002 110 56.7346 118.902 68 127C77.7347 120.401 84.0002 110 97.5002 99L109 101.5C96.1284 115.458 86.3291 126.212 68.9998 136.365C52.9175 126.789 42.9145 116.506 29.5 101.5Z"
			fill={'var(--white-color)'}
		/>
		<path
			d="M16.142 105.5C18.4721 104.548 25.5873 102.57 32 101C37.6272 107.948 42.4785 116.498 50 122.5C58.0732 129.562 59.3156 129.935 67.8156 134.676L67.9828 134.59C77.0775 129.936 79.7356 128.576 87 122.5C94.2039 116.543 100.897 107.911 107 101C113 102.486 116.046 102.277 123.642 105.5C125.919 106.451 127.447 107.368 131.142 110.5C133.703 112.656 134.926 114.204 136.642 117.5C137.99 119.619 138.678 121.113 139.642 125C139.88 127.044 139.724 128.791 139.642 131.5V133L112.5 145.5V163H27V145.5L0.142018 133V131.5C0.0041832 128.843 -0.0927556 127.338 0.142018 125C0.809061 121.95 1.4808 120.377 3.14202 117.5C5.50325 113.56 8.51059 110.567 8.5 110.5C8.48941 110.433 13.8119 106.452 16.142 105.5Z"
			fill={'var(--primary-color)'}
		/>
		<path
			d="M34.4922 49.0553C36.0704 42.4456 38.4675 38.6123 44.9922 31.5553C55.3108 43.0738 62.334 47.116 76.4922 51.0553C70.9364 46.1856 68.3703 43.3772 65.9922 38.0553C75.0777 47.3206 80.3335 50.2954 90.4922 53.0553C88.7354 48.7603 88.2329 46.3618 87.9922 42.0553C93.5987 54.3389 97.365 60.7463 105.492 71.0553C109.274 60.3911 110.894 54.4548 111.992 44.0553C112.472 34.4334 112.255 29.0144 106.992 19.0553C101.887 10.772 97.3705 7.47055 87.4922 3.0553C73.0634 -0.973577 65.051 -1.06305 50.9922 3.0553C39.9845 7.59461 35.7205 11.1595 30.9922 19.0553C27.3627 28.8181 26.2414 34.2983 27.4922 44.0553C29.6427 57.383 32.2974 64.676 37.9922 77.5553C34.9644 66.1886 33.8737 59.9078 34.4922 49.0553Z"
			fill="#2D2123"
		/>
	</BaseIcon>
);

const MaleIcon = (props: { dimension: number }) => (
	<BaseIcon dimension={props.dimension}>
		<path
			d="M18.142 106.5C20.4721 105.548 27.5873 103.57 34 102C39.6272 108.948 44.7941 112.825 52.3156 118.827C60.3889 125.89 62 126.405 70.5 131.146C79.7296 126.422 83.3756 123.937 90.6844 117.824C97.8882 111.867 101.897 108.411 108 101.5C114 102.986 118.046 103.277 125.642 106.5C127.919 107.451 129.447 108.368 133.142 111.5C135.703 113.656 136.926 115.204 138.642 118.5C139.99 120.619 140.678 122.113 141.642 126C141.88 128.044 141.724 129.791 141.642 132.5V134L114.5 146.5V164H29V146.5L2.14202 134V132.5C2.00418 129.843 1.90724 128.338 2.14202 126C2.80906 122.95 3.4808 121.377 5.14202 118.5C7.50325 114.56 10.5106 111.567 10.5 111.5C10.4894 111.433 15.8119 107.452 18.142 106.5Z"
			fill={'var(--primary-color)'}
		/>
		<path
			d="M43 100C46.0932 96.6918 46.8407 93.4459 47 86C56.6246 94.4997 61.8548 96.2404 71 96C80.9984 95.6148 86.2827 93.5998 95 86C95.0012 93.7611 96.101 96.6777 99.5 100C89.2118 109.374 83.0432 114.435 71 123C59.2808 115.353 53.1547 110.4 43 100Z"
			fill="#EDC286"
		/>
		<path
			d="M31 28C31.3235 38.1259 32.471 42.4984 34 51C37.3716 41.243 40.9109 37.1844 50 32C68.208 40.9559 79.2641 42.5837 101 37.5L108 51C109.216 46.4678 110.649 43.4831 111 37.5C112.148 24.6451 112.378 17.511 109 5.5C96.0585 5.03473 87.0619 2.8307 72 0C62.0166 0.348737 57.5473 1.87146 52.5 8C37.5243 9.05273 33.2125 13.8411 31 28Z"
			fill="#2D2123"
		/>
		<path
			d="M100 36C80.0612 41.3524 69.0361 39.1703 49.5 31C38.7617 35.766 35.6207 39.9492 34 49.5V54.5C30.2263 54.2433 28.9503 55.3508 28 59C28.3739 62.9792 28.9326 65.2553 31 69.5C33.0562 74.0512 34.6507 74.5216 38 73C40.4918 78.9663 42.5331 82.0058 47 87C64.7799 102.03 78.9239 101.21 95 87C99.4453 81.8204 101.177 78.6798 104 73C106.823 74.8189 108.343 73.6182 111 69.5C113.149 65.9845 113.781 63.7171 113.5 59C113.095 55.7267 112.137 54.599 108.5 54.5V49.5L100 36Z"
			fill="#F4D2A3"
		/>
		<path d="M114.5 164V155L140.5 143V157.5C139.683 160.866 138.789 162.669 134.5 164H114.5Z" fill="#EDC286" />
		<path d="M29 164V155L3 142.5V157.5C3.8167 160.866 4.71129 162.669 9 164H29Z" fill="#EDC286" />
		<path
			d="M33.5 102L43.0002 100C54.5002 111 59 114.5 70.2654 122.598C80.0002 116 86.0002 111 99.5002 100L108 101.635C95.1286 115.593 87.3293 121.95 70 132.103C53.9177 122.526 46.9145 117.006 33.5 102Z"
			fill={'var(--white-color)'}
		/>
		<path
			d="M114.5 155V146.5C114.5 146.5 141.417 133.492 141.911 133.082C142.405 132.672 144.362 133.864 143.941 135.121V140.218C143.424 141.381 142.981 141.819 141.911 142.257L114.5 155Z"
			fill={'var(--white-color)'}
		/>
		<path
			d="M29 155V146.5C29 146.5 2.58252 133.492 2.0888 133.082C1.59509 132.672 -0.362116 133.864 0.058897 135.121V140.218C0.575624 141.381 1.01945 141.819 2.0888 142.257L29 155Z"
			fill={'var(--white-color)'}
		/>
	</BaseIcon>
);

export const Avatar = ({ dimension = 100, url, sex }: { dimension?: number; url?: string; sex?: string }) => {
	if (!url) return sex === 'F' ? <FemaleIcon dimension={dimension} /> : <MaleIcon dimension={dimension} />;

	return (
		<div
			style={{
				width: dimension,
				height: dimension,
				borderRadius: '10px',
				backgroundImage: `url("${url}")`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		/>
	);
};
