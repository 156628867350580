import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getOrganizationSeason } from '../../api/club/entities/organization';
import { useProfiles } from '../profiles/use-profiles';

type SeasonContextType = {
	seasons: string[];
	currentSeason: string;
	selectedSeason: string;
	selectSeason: (season: string) => void;
};

export const SeasonContext = createContext<SeasonContextType | undefined>(undefined);

export const SeasonProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { selectedProfile } = useProfiles();
	const [seasonContext, setSeasonContext] = useState<{
		seasons: string[];
		currentSeason: string;
		selectedSeason: string;
	}>({
		currentSeason: '',
		selectedSeason: '',
		seasons: [],
	});

	const loadSeasonCallback = useCallback<(organizationId: string) => void>(
		async (organizationId: string) => {
			const { data } = await getOrganizationSeason({ organizationId });
			setSeasonContext({ ...data, selectedSeason: data.currentSeason });
		},
		[selectedProfile]
	);

	const selectSeason = (season: string) => {
		if (!seasonContext) return;
		setSeasonContext({ ...seasonContext, selectedSeason: season });
	};

	useEffect(() => {
		if (!selectedProfile) return;
		loadSeasonCallback(selectedProfile.ref_organization);
	}, [selectedProfile]);

	if (!seasonContext.selectedSeason) return <>{props.fallback}</>;
	return <SeasonContext.Provider value={{ ...seasonContext, selectSeason }}>{props.children}</SeasonContext.Provider>;
};

export const useSeason = () => {
	const context = useContext(SeasonContext);

	if (!context) {
		throw new Error('useSeasons must be used within SeasonProvider');
	}

	return context;
};
