import { Button } from '@/components/button';
import { Text } from '@gle/base-ui.typography.text';
import { useI18n } from '@gle/providers.i18n';
import { DownloadIcon } from 'lucide-react';
import moment from 'moment';
import { Post } from '../../../api/club/entities/posts-feed';
import { EmptyList, SkeletonListItem } from '../../../components';
import { Divider } from '../../../components/divider';
import Reactions from '../../../components/reactions/Reactions';
import { TextWithLinksRenderer } from '../../../components/text-with-links-renderer';

export const NoticeBoard = ({ feed, isLoading }: { feed: Post[]; isLoading: boolean }) => {
	return (
		<div>
			<NoticeList notice={feed} isLoading={isLoading} />
		</div>
	);
};

const NoticeList = ({ notice, isLoading }: { notice: Post[]; isLoading: boolean }) => {
	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				<SkeletonListItem />
				<SkeletonListItem />
				<SkeletonListItem />
			</div>
		);
	}

	return (
		<div className="pb-5">
			{!notice.length && !isLoading ? (
				<EmptyList />
			) : (
				<div className={'flex flex-col gap-5'}>
					{notice.map((notice, i) => (
						<NoticeBoardCard key={i} notice={notice} />
					))}
				</div>
			)}
		</div>
	);
};

const NoticeBoardCard = ({ notice }: { notice: Post }) => {
	const { messages } = useI18n();
	const reaction = notice.reactions.length ? notice.reactions[0].reaction : '';
	const attachment = notice.attachment;

	return (
		<div className={'rounded-md border border-solid border-gray-200 p-0'}>
			<div className={'flex justify-between bg-slate-100 p-2'}>
				<div className={'flex flex-col'}>
					<p className="text-sm font-medium text-black">{notice.authorName}</p>
					<p className="text-xs font-light text-slate-500">{moment(notice.createdAt).fromNow()}</p>
				</div>
			</div>
			<div className="p-2">
				<div className={'space mb-3 mt-2'}>
					{notice.content.html ? (
						<div
							className="html-content-preview"
							dangerouslySetInnerHTML={{ __html: notice.content.html }}></div>
					) : (
						<TextWithLinksRenderer text={notice.content.data} />
					)}
				</div>
				{attachment && (
					<div className={'mt-5 flex flex-row items-center gap-1.5'}>
						<a href={attachment?.url} target={'_blank'}>
							<Button size="sm" variant={'secondary'}>
								<DownloadIcon size={18} className="mr-2" />
								{messages['download']}
							</Button>
						</a>
						<Text size="s" color="gray">
							{attachment.fileName}
						</Text>
					</div>
				)}
				<Divider style={{ marginBlock: 10 }} />
				<Reactions reaction={reaction} postId={notice._id} type={notice.type} />
			</div>
		</div>
	);
};
