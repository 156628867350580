import { useEffect, useState } from "react";
import { useProfiles } from "../context/profiles/use-profiles";
import { getNotificationsList } from "../pages/private/notifications/api";
import { Notification } from "../pages/private/notifications/types";
import { useBoolean } from "./use-boolean";

export const useNotifications = () => {
    const [notificationsList, setNotificationsList] = useState<Notification[]>([]);

    const [isLoading, setIsLoading] = useBoolean();

    const { selectedProfile } = useProfiles()

    const getNotifications = async () => {

        if (!selectedProfile?._id) return
        try {
            setIsLoading(true);
            const { data } = await getNotificationsList(selectedProfile?._id, {})
            setNotificationsList(data.reverse())
        } catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }


    }

    useEffect(() => {
        getNotifications()
    }, []);

    return { notificationsList, isLoading }
}