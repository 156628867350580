import { Link } from 'react-router-dom';
import { LoadingLayout } from '../../../components';
import { UserHeader } from '../../../components/avatar-block';
import { Carousel } from '../../../components/carousel';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useSponsors } from '../../../context/sponsors';
import { CardsList } from './cards-list';

export const Home = () => {
	const { orgPerson, organization } = useOrgPerson();
	const { sponsors } = useSponsors();

	if (!orgPerson || !organization) {
		return <LoadingLayout />;
	}

	return (
		<div className={'flex h-full flex-col justify-between'}>
			<div>
				<Link to="/profile">
					<UserHeader
						firstName={orgPerson.name}
						lastName={orgPerson.surname}
						avatarUrl={orgPerson.personMetadata.avatar}
						organization={{
							name: organization.name,
							logoUrl: organization.logo_url,
						}}
					/>
				</Link>
				<CardsList orgPerson={orgPerson} />
			</div>
			<Carousel sponsors={sponsors} />
		</div>
	);
};
