import { Badge } from '@/components/badge';
import moment from 'moment';
import { OrgPersonConsent } from './types';

export const ConsentAnswered = (props: { orgPersonConsent: OrgPersonConsent; onEdit: () => void }) => {
	const status = props.orgPersonConsent.status;
	const lastHistoryElement = !!props.orgPersonConsent?.history?.length
		? props.orgPersonConsent.history[props.orgPersonConsent.history.length - 1]
		: undefined;

	const lastUpdate = lastHistoryElement?.changedAt;
	const hasSignature = !!props.orgPersonConsent.signatureId;

	return (
		<div className="flex items-center justify-end">
			<div className="flex flex-col items-end">
				{status === 'given' && (
					<Badge variant={'success'}>{`Accettato${hasSignature ? ' con firma' : ''}`}</Badge>
				)}
				{status === 'given-without-signature' && <Badge variant={'warning'}>Accettato, senza firma</Badge>}
				{status === 'denied' && <Badge variant={'destructive'}>Negato</Badge>}
				{status === 'removed' && <Badge variant={'destructive'}>Rimosso</Badge>}

				<p className="mb-2 mt-2 text-xs text-slate-500">
					in data {moment(lastUpdate).format('DD/MM/YYYY HH:mm')}
				</p>
				{hasSignature ? (
					<p className={'text-xs font-medium text-zinc-300'}>
						I consensi firmati non possono essere modificati
					</p>
				) : (
					<p className="cursor-pointer text-xs font-medium text-sky-700 underline" onClick={props.onEdit}>
						Modifica
					</p>
				)}
			</div>
		</div>
	);
};
