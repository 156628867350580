import { AddressSection, ContactsSection } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { Flex } from '../../../../../core/@ui/flex';
import { ViewProps } from '../types';

export const ContactsView = (props: ViewProps) => {
	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<Flex gap={10} flexDirection="column" marginBlock={10}>
				<ContactsSection orgPerson={props.orgPerson} />
				<AddressSection orgPerson={props.orgPerson} />
			</Flex>
		</>
	);
};
