import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { UploadIcon } from 'lucide-react';
import React, { ChangeEvent, useState } from 'react';
import { TPepaFileUploaded, uploadFileToPepa } from '../../api/pepa/entities/pepa';
import { useReactNative } from '../../hooks/use-react-native';

interface TProps {
	onUploadedFile: (uploadedFile: TPepaFileUploaded) => void;
}

const UploaderOnPepa = (props: TProps) => {
	const { messages } = useI18n();
	const [uploadingPercentage, setUploadingPercentage] = useState(-1);
	const { isFromApp, sendMessageToRN, hasPermissionCamera, isAppVersionGte } = useReactNative();

	const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];
			const resp = await handleUpload(file);
			props.onUploadedFile({ ...resp.data, file_name: file.name });
			setUploadingPercentage(-1);
		}
	};

	const handleUpload = (file: File) => {
		if (getMimes().includes(file.type)) {
			const config = {
				onUploadProgress: function (progressEvent: any) {
					var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadingPercentage(percentCompleted);
				},
			};

			let formData = new FormData();
			formData.append('file', file);
			return uploadFileToPepa({ formData, config });
		} else {
			throw new Error(`${messages['type']} ${file.type} ${messages['not_handled']}`);
		}
	};

	const getMimes = (): string => {
		return 'application/pdf,image/jpeg,image/png,image/jpg,text/plain,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
	};

	const hiddenFileInput: any = React.useRef(null);
	const handleOnClick = () => {
		if (!hiddenFileInput.current?.click) return;

		if (isFromApp && isAppVersionGte('1.2.2') && !hasPermissionCamera) {
			void sendMessageToRN('request-camera-permission', {});
		}

		hiddenFileInput.current.click();
	};
	return (
		<div className={'uploaderWrapper'}>
			<Button isLoading={uploadingPercentage >= 0} onClick={handleOnClick}>
				<UploadIcon size={18} className="mr-2" />
				{'Carica'}
			</Button>
			<input
				accept={getMimes()}
				type="file"
				ref={hiddenFileInput}
				onChange={onChange}
				style={{ display: 'none' }}
			/>
		</div>
	);
};

export default UploaderOnPepa;
