import { ScrollArea, ScrollBar } from '@/components/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs';
import { Heading } from '@gle/base-ui.typography.heading';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useActiveKey } from '../../../hooks/use-active-key';
import { useBoolean } from '../../../hooks/use-boolean';
import { ContactsTab } from './tabs/contacts/contacts';
import { GeneralTab } from './tabs/general/general';
import { MedicalTab } from './tabs/medical/medical';
import { OtherTab } from './tabs/other/other';
import { SportTab } from './tabs/sport/sport';
import { StatisticsTab } from './tabs/statistics/statistics';

export const Profile = () => {
	const [activeKey, setActiveKey] = useActiveKey();
	const [isEditing, setisEditing] = useBoolean();
	const [isRenaming, setIsRenaming] = useBoolean();

	const { orgPerson } = useOrgPerson();

	return (
		<div>
			<Heading level={3} className="mb-5">
				Profilo
			</Heading>

			<Tabs defaultValue="general">
				<ScrollArea className="pb-2">
					<div className="relative h-10 w-full">
						<TabsList className="absolute flex h-10">
							<TabsTrigger
								value="general"
								onClick={() => setActiveKey('general')}
								disabled={activeKey !== 'general' && isEditing}>
								Dati Generici
							</TabsTrigger>
							<TabsTrigger
								value="contacts"
								onClick={() => setActiveKey('contacts')}
								disabled={activeKey !== 'contacts' && (isEditing || isRenaming)}>
								Contatti
							</TabsTrigger>
							<TabsTrigger
								value="medical"
								onClick={() => setActiveKey('medical')}
								disabled={activeKey !== 'medical' && (isEditing || isRenaming)}>
								Dati medici
							</TabsTrigger>
							{orgPerson?.metadata.athlete?.technicalProfile && (
								<TabsTrigger
									value="sports"
									onClick={() => setActiveKey('sport')}
									disabled={activeKey !== 'sport' && (isEditing || isRenaming)}>
									Dati sportivi
								</TabsTrigger>
							)}
							<TabsTrigger
								value="statistics"
								onClick={() => setActiveKey('statistics')}
								disabled={activeKey !== 'statistics' && (isEditing || isRenaming)}>
								Presenze
							</TabsTrigger>
							<TabsTrigger
								value="other"
								onClick={() => setActiveKey('other')}
								disabled={activeKey !== 'other' && (isEditing || isRenaming)}>
								Altro
							</TabsTrigger>
						</TabsList>
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>

				<TabsContent value="general">
					<GeneralTab
						isRenaming={isRenaming}
						onChangeRenaming={(value: boolean) => setIsRenaming(value)}
						isEditing={isEditing}
						onChangeEdit={(value: boolean) => setisEditing(value)}
					/>
				</TabsContent>

				<TabsContent value="contacts">
					<ContactsTab isEditing={isEditing} onChangeEdit={(value: boolean) => setisEditing(value)} />
				</TabsContent>
				<TabsContent value="medical">
					<MedicalTab isEditing={isEditing} onChangeEdit={(value: boolean) => setisEditing(value)} />
				</TabsContent>
				{orgPerson?.metadata.athlete?.technicalProfile && (
					<TabsContent value="sports">
						<SportTab isEditing={isEditing} onChangeEdit={(value: boolean) => setisEditing(value)} />
					</TabsContent>
				)}
				<TabsContent value="statistics">
					<StatisticsTab />
				</TabsContent>
				<TabsContent value="other">
					<OtherTab isEditing={isEditing} onChangeEdit={(value: boolean) => setisEditing(value)} />
				</TabsContent>
			</Tabs>
		</div>
	);
};
