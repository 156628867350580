import { OrgPerson } from '../../core/orgPerson/types';
import { formatDate } from '../../utils/format-date';
import { Section } from '../section';

export const BirthdateSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	if (!orgPerson.personMetadata.dates?.birthdate) return <></>;

	return (
		<Section title={'Data di nascita'}>
			<p>{formatDate(orgPerson.personMetadata.dates?.birthdate)}</p>
		</Section>
	);
};
