import { isValidPhone } from '@gle/base-ui.inputs.input-phone';
import * as yup from 'yup';

export const contactsFormValidationSchema = (messages: { [key: string]: string }) => {
    return {
        contacts: yup.object().shape({
            primaryPhone: yup
                .string()
                .nullable()
                .test('valid-phone', messages['invalid_phone_number'], value =>
                    value ? isValidPhone(value!) : true
                ),

        }),
    };
};