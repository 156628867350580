import { EConanEvent } from '@golee/conan-sdk';
import { ConanProvider, useConan } from '@golee/gle-conan-tracker';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactNative } from '../../hooks/use-react-native';
import { useProfiles } from '../profiles/use-profiles';

export const TrackingProvider: React.FC<PropsWithChildren> = (props: any) => {
	const { selectedProfile } = useProfiles();
	const { isFromApp } = useReactNative();

	return selectedProfile ? (
		<ConanProvider
			distinctId={selectedProfile._id}
			baseUrl={window._env_.REACT_APP_API_URL_CONAN_SERVICE}
			defaultProperties={{ golee_club: selectedProfile.ref_organization, isFromApp }}>
			{props.children}
		</ConanProvider>
	) : (
		<>{props.children}</>
	);
};

export const ConanPageTracker = () => {
	const { trackEvent } = useConan();
	const location = useLocation();

	useEffect(() => {
		if (location) {
			void trackEvent(EConanEvent.PAGE_VIEW, { path: location.pathname });
		}
	}, [location]);

	return null;
};
