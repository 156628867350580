import { Button } from '@/components/button';
import { Logo } from '@gle/base-ui.logo';
import { Text } from '@gle/base-ui.typography.text';
import { BellIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useMobileOs } from '../../hooks/use-mobile-os';
import { Menu } from './menu';

const AppIcon = styled.div`
	background-color: #fa6762;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		height: 30px;
	}
`;

const DownloadAppBanner = () => {
	const os = useMobileOs();

	const osConfig = {
		android: {
			hint: 'Disponibile su Google Play',
			link: 'https://play.google.com/store/apps/details?id=com.goleedev.golee',
		},
		ios: {
			hint: `Disponibile sull'App Store`,
			link: 'https://apps.apple.com/it/app/golee/id6449704513',
		},
	};

	if (os !== 'android' && os !== 'ios') {
		return <></>;
	}

	return (
		<div className="flex h-14 items-center justify-between bg-slate-800 px-4">
			<div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
				<AppIcon>
					<img src="/images/golee_white_g.png" />
				</AppIcon>

				<div>
					<Text color={'white'} weight="medium">
						Golee
					</Text>
					<Text color={'white'} size="s" weight="light">
						{osConfig[os].hint}
					</Text>
				</div>
			</div>
			<a href={osConfig[os].link} target="_blank">
				<Button className="uppercase" size={'sm'} variant={'outline'}>
					Scarica l'app
				</Button>
			</a>
		</div>
	);
};

export const Header = (props: { hideNavigation: boolean; isDownloadAppBannerVisible: boolean }) => {
	return (
		<div
			className={`fixed left-0 top-0 z-10 w-full bg-white`}
			style={{ height: props.isDownloadAppBannerVisible ? '7.5rem' : '4rem' }}>
			{props.isDownloadAppBannerVisible && <DownloadAppBanner />}
			<div className={'mx-auto flex h-16 items-center justify-between px-4'}>
				{!props.hideNavigation && <Menu />}

				<div className={'flex grow justify-center'}>
					<Link to={'/home'}>
						<Logo />
					</Link>
				</div>

				{!props.hideNavigation && (
					<div className={'flex gap-2'}>
						<Link to="/notifications" className={'w-[28px]'}>
							<BellIcon color={'var(--primary-color)'} size={24} />
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};
