import { TObjTranche } from '@golee/gle-types';
import { useEffect } from 'react';
import { CashedTrancheInfo, NotCashedTrancheInfo, SkeletonButton } from '../../../components';
import { InvoiceDownloadButton } from '../../../components/invoice-button-download';
import { ReceiptDownloadButton } from '../../../components/receipt-download-button';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useReceipt } from '../../../context/tranches';
import { usePaymentInfo } from '../../../hooks/use-payment-info';
import { PaymentButton } from '../../public/pay/payment-button';
import { TrancheDetailLayout } from './tranche-detail-layout';

export const TrancheDetailNotCashed = ({ tranche }: { tranche: TObjTranche }) => {
	const { paymentInfo, loadPaymentInfo, isLoading } = usePaymentInfo();
	const { orgPerson } = useOrgPerson();

	useEffect(() => {
		void loadPaymentInfo(orgPerson.organizationId, tranche._id);
	}, []);

	function getPrimaryCTA() {
		if (tranche.amount.total_amount === 0) return null;
		if (paymentInfo)
			return (
				<PaymentButton
					organizationId={orgPerson.organizationId}
					trancheId={tranche._id}
					availablePaymentMethods={paymentInfo.availablePaymentMethods}
				/>
			);
	}

	return (
		<TrancheDetailLayout
			tranche={tranche}
			PrimaryCTA={isLoading ? <SkeletonButton /> : <>{getPrimaryCTA()}</>}
			TrancheInfo={
				<NotCashedTrancheInfo
					tranche={tranche}
					suggestGoleePayActivation={!paymentInfo}
					isLoadingPaymentInfo={isLoading}
				/>
			}
		/>
	);
};

export const TrancheDetailCashed = ({ tranche }: { tranche: TObjTranche }) => {
	const { receipt } = useReceipt();

	return (
		<TrancheDetailLayout
			tranche={tranche}
			PrimaryCTA={
				<div>
					{tranche.invoice?.id && <InvoiceDownloadButton invoiceId={tranche.invoice.id} />}
					{tranche.receipt?.id && <ReceiptDownloadButton documentUrl={receipt?.documentUrl} />}
				</div>
			}
			TrancheInfo={<CashedTrancheInfo tranche={tranche} />}
		/>
	);
};
