import React, { createContext, useEffect, useState } from 'react';
import { Organization, getOrganization } from '../../api/club/entities/organization';
import client from '../../api/people/client';
import { OrgPerson } from '../../core/orgPerson/types';
import { useProfiles } from '../profiles/use-profiles';
import { useSeason } from '../season';

export const loadOrgPerson = async (currentSeason: string) => {
	try {
		const { data } = await client.get<OrgPerson>(`org-people/me`, {
			headers: {
				'x-gle-current-season': currentSeason,
				'x-gle-selected-season': currentSeason, //let it be
			},
		});
		return data;
	} catch (err) {
		throw err;
	}
};

type OrgPersonContextType = {
	orgPerson: OrgPerson;
	organization?: Organization;
	refresh: () => void;
};

export const OrgPersonContext = createContext<OrgPersonContextType | undefined>(undefined);

export const OrgPersonProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { currentSeason } = useSeason();
	const { selectedProfile } = useProfiles();

	const [organization, setOrganization] = useState<Organization | undefined>();
	const [orgPerson, setOrgPerson] = useState<OrgPerson>();

	useEffect(() => {
		void init({ loadOrganization: true });
	}, []);

	//SMELL
	const init = async (config?: { loadOrganization?: boolean }) => {
		if (!selectedProfile) return;

		try {
			if (config?.loadOrganization) {
				const data = await getOrganization({ organizationId: selectedProfile.ref_organization });
				setOrganization(data);
			}

			const data = await loadOrgPerson(currentSeason);
			setOrgPerson(data);
		} catch (err) {
			//TODO: navigate to error page
		}
	};

	const refresh = async () => {
		void init();
	};

	if (!orgPerson) {
		return <>{props.fallback}</>;
	}

	return (
		<OrgPersonContext.Provider
			value={{
				orgPerson,
				refresh,
				organization,
			}}>
			{props.children}
		</OrgPersonContext.Provider>
	);
};
