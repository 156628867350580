import { useI18n } from '@gle/providers.i18n';
import { OrgPerson } from '../../core/orgPerson/types';
import { Section } from '../section';

export const SchoolSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const { messages } = useI18n();

	return (
		<>
			<Section title={messages['attended_school']}>
				{orgPerson.personMetadata.academics?.attendedSchool || '-'}
			</Section>

			<Section title={messages['school_specialism']}>
				{orgPerson.personMetadata.academics?.schoolSpecialism || '-'}
			</Section>
		</>
	);
};
