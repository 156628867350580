import { EmptyList } from '../../empty-list';
import { ProfileCard } from '../athlete-card';
import { useProfiles } from '../../../context/profiles/use-profiles';

export const ProfilesList = () => {
	const { profiles } = useProfiles();

	if (!profiles || !profiles.length) {
		return <EmptyList />;
	}

	return (
		<div>
			<p className="uppercase text-gray-400 text-xs mb-2">{'Seleziona profilo'}</p>
			{profiles.map(profile => (
				<ProfileCard profile={profile} key={profile._id} />
			))}
		</div>
	);
};
