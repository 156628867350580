import React, { createContext, useContext, useEffect, useState } from 'react';
import { getSponsors, Sponsor } from '../../api/club/entities/sponsors';

export const SponsorsContext = createContext<{ sponsors: Sponsor[] } | undefined>(undefined);

export const SponsorsProvider = (props: { children: React.ReactNode }) => {
	const [sponsors, setSponsors] = useState<Sponsor[]>([]);

	useEffect(() => {
		getSponsors().then(s =>
			setSponsors(s.filter(s => s.isVisibleInApp).sort(s => (s.category === 'PRIMARY' ? -1 : 1)))
		);
	}, []);

	return (
		<SponsorsContext.Provider
			value={{
				sponsors,
			}}>
			{props.children}
		</SponsorsContext.Provider>
	);
};

export const useSponsors = () => {
	const context = useContext(SponsorsContext);

	if (!context) {
		throw new Error('useSponsors must be used within SponsorsProvider');
	}

	const primarySponsors = context.sponsors.filter(s => s.category === 'PRIMARY');
	const secondarySponsors = context.sponsors.filter(s => s.category === 'SECONDARY');
	return { ...context, primarySponsors, secondarySponsors };
};
