import { Button } from '@/components/button';
import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { ErrorIcon } from '../../assets';

export const Error = ({ ...props }: { error?: any; onRetry?: () => void }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				margin: '20px auto',
				width: '100%',
				maxWidth: 450,
				textAlign: 'center',
			}}>
			<ErrorIcon />
			<Heading level={3} style={{ marginBlock: 10 }}>
				Oops!
			</Heading>

			<Text>Qualcosa è andato storto durante il caricamento dei dati.</Text>

			<Button onClick={props.onRetry} className="mt-5">
				Riprova
			</Button>
		</div>
	);
};
