import { Heading } from '@gle/base-ui.typography.heading';
import { useEffect, useState } from 'react';
import { getPostsFeed, Post } from '../../../api/club/entities/posts-feed';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { OrgPerson } from '../../../core/orgPerson/types';
import { NoticeBoard } from '../notice-board';

export const Posts = () => {
	const { orgPerson } = useOrgPerson();
	const [feed, setFeed] = useState<Post[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		void retrieveNotice(orgPerson);
	}, []);

	const retrieveNotice = async (orgPerson: OrgPerson) => {
		setIsLoading(true);
		const groups = orgPerson.groups?.map(g => g.groupId) || [];
		try {
			const resp = await getPostsFeed(orgPerson.roles || [], groups);
			setFeed(resp);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={'hide-scrollbar'}>
			<Heading level={3} className="mb-5">
				Bacheca
			</Heading>
			<NoticeBoard feed={feed} isLoading={isLoading} />
		</div>
	);
};
