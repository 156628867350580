import { Logo } from '@gle/base-ui.logo';
import { useI18n } from '@gle/providers.i18n';
import { Buffer } from 'buffer';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Currency, Error as ErrorPage, LoadingLayout, NotCashedTrancheInfo } from '../../../components';
import { PaymentButton } from './payment-button';
import { usePaymentInfo } from './use-payment-info-public';

type Base64Result = {
	organizationId: string;
	trancheId: string;
};

const getPaymentInfoFromBase64 = (encoded: string): Base64Result => {
	try {
		const decoded: Base64Result = JSON.parse(Buffer.from(encoded, 'base64').toString());
		return decoded;
	} catch (e) {
		console.error('Error decode', encoded);
		throw new Error('unable-to-decode-base64');
	}
};

export const Pay = () => {
	const { messages } = useI18n();
	const params: any = useParams();

	const { loadPaymentInfo, paymentInfo, isLoading, error } = usePaymentInfo();

	useEffect(() => {
		const { organizationId, trancheId } = getPaymentInfoFromBase64(params.encoded);
		void loadPaymentInfo(organizationId, trancheId);
	}, []);

	if (isLoading || !paymentInfo) {
		return <LoadingLayout />;
	}

	if (error) {
		return <ErrorPage />;
	}

	return (
		<div className="mx-auto max-w-lg p-8">
			<div>
				<Club name={paymentInfo.clubName} logo={paymentInfo.clubLogo} />

				<p className="text-xl font-bold">{paymentInfo.reason}</p>
				<p className="text">{paymentInfo.denomination}</p>

				<div className="mb-5 mt-5 flex flex-col gap-1">
					<p className="text-3xl font-bold">
						<Currency value={paymentInfo.amount} />
					</p>
					<p className="text-sm font-medium text-gray-400">+ {messages['fee']} *</p>
				</div>

				{paymentInfo.cashed ? (
					<p className="rounded border border-green-600 bg-green-200 p-2 text-center font-medium text-green-900">
						PAGATO
					</p>
				) : (
					<PaymentButton
						organizationId={paymentInfo.organizationId}
						trancheId={paymentInfo.trancheId}
						availablePaymentMethods={paymentInfo.availablePaymentMethods}
					/>
				)}
			</div>

			<div className="mt-5 border-t border-solid border-gray-100 pt-5">
				<NotCashedTrancheInfo tranche={{}} suggestGoleePayActivation={false} isLoadingPaymentInfo={false} />
			</div>

			<p className="mt-10 text-xs text-gray-400">* 1% con Carta o Bonifico, 4% con Klarna in 3 rate</p>

			<div className="mt-5 flex items-center justify-center">
				<Logo />
			</div>
		</div>
	);
};

const Club = ({ logo, name }: { logo?: string; name: string }) => {
	return (
		<div className="mb-5 flex items-center gap-3 border-b border-solid border-gray-100 pb-5">
			{logo && <img src={logo} style={{ width: 80 }} />}
			<p className="text-3xl font-bold">{name}</p>
		</div>
	);
};
