type Props = {
	backgroundColor?: string;
	cardColor?: string;
	children: React.ReactNode;
	header?: React.ReactNode;
	topBar?: React.ReactNode;
};

export const MainLayout = ({
	backgroundColor = 'var(--primary-color)',
	cardColor = 'var(--white-color)',
	...props
}: Props) => {
	return (
		<>
			<div
				style={{
					background: backgroundColor,
					padding: '20px 20px 50px',
				}}>
				{props.header && (
					<div
						style={{
							height: 60,
							display: 'flex',
							alignItems: 'flex-start',
							flexDirection: 'column',
							justifyContent: 'center',
							margin: '0 auto',
							maxWidth: 600,
						}}>
						{props.header}
					</div>
				)}
			</div>

			<div
				style={{
					marginTop: -20,
					width: '100%',
					background: cardColor,
					borderTopLeftRadius: 20,
					borderTopRightRadius: 20,
					boxShadow: '0 -5px 5px 0 rgba(0,0,0,0.10)',
					padding: 20,
				}}>
				<div style={{ margin: '0 auto', maxWidth: 600 }}>{props.children}</div>
			</div>
		</>
	);
};
