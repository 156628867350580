import { Text } from '@gle/base-ui.typography.text';
import { useI18n } from '@gle/providers.i18n';

const emojiOptions = [
	{ description: 'funny', emoji: '😄' },
	{ description: 'like', emoji: '👍' },
	{ description: 'love', emoji: '❤️' },
	{ description: 'insightful', emoji: '💡' },
	{ description: 'applause', emoji: '👏' },
	{ description: 'fire', emoji: '🔥' },
];

const EmojiPicker = (props: any) => {
	const { messages } = useI18n();

	const handleEmojiClick = (emoji: string) => {
		props.onSelect(emoji);
	};

	return (
		<div className={'px absolute -top-12 left-1.5 inline-flex gap-x-1.5 rounded-full bg-gray-100 p-1 px-2'}>
			{emojiOptions.map((item, index) => (
				<div
					key={index}
					className={'cursor-pointer rounded p-0.5 hover:bg-gray-200'}
					onClick={() => handleEmojiClick(item.emoji)}>
					<Text size={'xxl'}>{item.emoji}</Text>
				</div>
			))}
		</div>
	);
};

export default EmojiPicker;
