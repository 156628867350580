import { isValidDate } from '@gle/base-ui.inputs.input-date';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Metadata } from '../../../../../../api/people/entities/metadata';
import { GroupsSection, MedicalExaminationSection, PaymentsSection } from '../../../../../../components';
import { MembershipNumberSection } from '../../../../../../components/org-person-sections/membership-number';
import { SubmitButtons } from '../../../../../../components/submit-buttons/submit-buttons';
import toast from '../../../../../../components/toast/toast';
import { Flex } from '../../../../../../core/@ui/flex';
import { InputDateField } from '../../../../../../core/inputs/DatePicker';
import { MetadataForm } from '../../../../../../core/metadata';
import { sendMemberChangedDataNotification, translationsMap } from '../../../utils';
import { FormProps } from '../../types';
import { metadataDatesValidationSchema } from '../utils';

export const GeneralForm = (props: FormProps) => {
	const { messages } = useI18n();
	const { trackEvent } = useConan();

	const onSubmit = async (values: Metadata, set: any, formTouchedValues?: { dates?: {} }) => {
		await set(
			values,
			() => {
				toast.error(messages['save_error']);
			},
			() => {
				toast.success(messages['save_successful']);
			}
		);

		const touchedValues = [];

		if (formTouchedValues?.dates) {
			for (const value in formTouchedValues.dates) {
				touchedValues.push(translationsMap.get(value));
			}
		}
		props.onSave();
		props.onChangeEdit(false);

		if (touchedValues.length) {
			sendMemberChangedDataNotification(props.orgPerson, touchedValues as string[]);
			void trackEvent('family.orgPerson-changed-data-from-app', {
				values: touchedValues.join(', '),
				section: 'general',
			});
		}
	};
	const validationSchema = yup.object().shape({
		person: yup.object().shape({
			dates: metadataDatesValidationSchema(messages),
		}),
	});
	return (
		<>
			<div>
				<MetadataForm orgPersonId={props.orgPerson?.orgPersonId} season={props.orgPerson.season}>
					{({ data, set, isSaving }) => {
						return (
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={data}
								onSubmit={() => {}}>
								{formContext => {
									return (
										<Form>
											<SubmitButtons
												formContext={formContext}
												isSaving={isSaving}
												isEditing={props.isEditing}
												onChangeEdit={value => props.onChangeEdit(value)}
												onSubmit={() =>
													onSubmit(
														formContext.values,
														set,
														formContext.dirty
															? {
																	//@ts-ignore
																	dates: formContext.touched?.person?.dates,
																}
															: undefined
													)
												}
											/>
											<Flex gap={10} flexDirection="column" marginBlock={10}>
												<InputDateField
													name="person.dates.birthdate"
													label={messages['birthdate']}
													isClearable
													onChange={(value, formats) => {
														formContext.setFieldValue(
															'person.dates.birthdate',
															value && isValidDate(value) ? formats.ISOString : value
														);
													}}
												/>
												<GroupsSection orgPerson={props.orgPerson} />
												<MedicalExaminationSection orgPerson={props.orgPerson} />
												<PaymentsSection />
												<MembershipNumberSection orgPerson={props.orgPerson} />
											</Flex>
										</Form>
									);
								}}
							</Formik>
						);
					}}
				</MetadataForm>
			</div>
		</>
	);
};
