import { OrgPerson } from '../../../core/orgPerson/types';
import { ContactType } from '../../../pages/private/types';
import client from '../client';

export const addContactToOrgPerson = async (
	payload: Pick<OrgPerson, 'orgPersonId'> & {
		contact: string;
		contactType: ContactType;
		season: string;
	}
) => {
	return client.post<void>(`org-people/cmd/add-contact`, payload, {
		headers: {
			'x-gle-current-season': payload.season,
			'x-gle-selected-season': payload.season,
		},
	});
};

export const changeContactToOrgPerson = async (
	payload: Pick<OrgPerson, 'orgPersonId'> & {
		contact: string;
		contactType: ContactType;
		season: string;
	}
) => {
	return client.post<void>(`org-people/cmd/change-contact`, payload, {
		headers: {
			'x-gle-current-season': payload.season,
			'x-gle-selected-season': payload.season,
		},
	});
};

export const removeContactFromOrgPerson = async (
	payload: Pick<OrgPerson, 'orgPersonId'> & {
		contactType: ContactType;
		season: string;
	}
) => {
	return client.post<void>(`org-people/cmd/remove-contact`, payload, {
		headers: {
			'x-gle-current-season': payload.season,
			'x-gle-selected-season': payload.season,
		},
	});
};
