import client from '../client';

type Notification =
	| {
			occurrenceType: 'DocumentAttachedByOrgPerson';
			ownerType: 'OrgPersonDocuments';
			metadata: { documentName: string; documentUrl: string };
			origin: 'system';
	  }
	| {
			occurrenceType: 'DocumentRequestedByOrgPerson';
			ownerType: 'OrgPersonDocuments';
			metadata: { documentName: string };
			origin: 'user';
	  }
	| {
			occurrenceType: 'OrgPersonChangedDataFromApp';
			ownerType: 'OrgPersonMetadata';
			metadata: { changedProperties: string };
			origin: 'system';
	  };

export const sendInstantNotification = async (
	ownerId: string,
	organizationId: string,
	notificationData: Notification
) => {
	try {
		const body = {
			ownerType: notificationData.ownerType,
			ownerId,
			organizationId,
			occurrenceType: notificationData.occurrenceType,
			metadata: notificationData.metadata,
		};

		const { origin } = notificationData;
		const uri = `notification/instant/by-${origin}`;

		await client.post(uri, body);
	} catch (err) {
		console.error(err);
	}
};
