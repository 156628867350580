import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';

export const CashedTrancheInfo = ({ tranche }: { tranche: TObjTranche }) => {
	const { messages } = useI18n();

	return (
		<>
			<p>
				{tranche.type === 'ENT'
					? `Pagata il: ${tranche.cashed_date}`
					: `Pagata dalla società il: ${tranche.cashed_date}`}
				<br />
				<br />
			</p>

			<p>
				{messages['payment_made_with']} <strong>{messages[`payment_method.${tranche.payment_method}`]}</strong>
				<br />
				<br />
				{tranche.type === 'ENT' ? (
					<p>{messages['payment_has_been_cashed_by_club']}</p>
				) : (
					<p>Il pagamento è stato effettuato dalla società</p>
				)}
			</p>
		</>
	);
};
