import { Button } from '@/components/button';
import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { DownloadIcon } from 'lucide-react';
import { SkeletonButton } from '../skeleton';

export const ReceiptDownloadButton = ({ documentUrl }: { documentUrl?: string }) => {
	const { messages } = useI18n();
	const { trackEvent } = useConan();

	return !documentUrl ? (
		<SkeletonButton />
	) : (
		<a href={documentUrl} target={'_blank'}>
			<Button variant={'secondary'} onClick={() => trackEvent('family.receipt-opened')}>
				<DownloadIcon size={18} className="mr-2" /> {messages['receipt']}
			</Button>
		</a>
	);
};
