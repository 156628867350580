import { Metadata, MetadataParams } from '../../api/people/entities/metadata';
import { useMetadata } from '../../hooks/use-metadata';
import { SkeletonParagraph } from '../Skeleton/Skeleton';

export type MetadataProps = MetadataParams;

export type MetadataFormProps = MetadataProps & {
	children: (props: MetadataInnerFormProps) => React.ReactNode;
};

export type MetadataInnerFormProps = {
	data: Metadata;
	set: (payload: Metadata, onError?: (err?: any) => void, onComplete?: () => void) => Promise<void>;
	isSaving?: boolean;
};

export const MetadataForm = (props: Omit<MetadataFormProps, 'role'>) => {
	const metadata = useMetadata({ season: props.season, orgPersonId: props.orgPersonId });
	const { isLoading, ...rest } = metadata;

	if (isLoading) {
		return (
			<div style={{ marginTop: 20 }}>
				<SkeletonParagraph rows={5} height={35} />
			</div>
		);
	}

	// if (error) {
	// 	return <Error />;
	// }

	if ((!rest.data || !Object.keys(rest.data).length) && props.orgPersonId) {
		return <></>;
	}

	return <>{props.children({ ...rest })}</>;
};
