import { MedicalExaminationSection } from '../../../../../components';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { Flex } from '../../../../../core/@ui/flex';
import { ViewProps } from '../types';

export const MedicalView = (props: ViewProps) => {
	if (!props.orgPerson) return <></>;

	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<Flex gap={10} flexDirection="column" marginBlock={10}>
				<MedicalExaminationSection showAllData orgPerson={props.orgPerson} />
			</Flex>
		</>
	);
};
