import { OrgPerson } from '../../core/orgPerson/types';
import { formatDate } from '../../utils/format-date';
import { Section } from '../section';

export const MembershipSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	return (
		<>
			<Section title={'Matricola'}>
				<p>{orgPerson.metadata.athlete?.technicalProfile?.registrationNumber || '-'}</p>
			</Section>
			<Section title={'Data primo tesseramento'}>
				<p>
					{orgPerson.personMetadata.dates?.athleteMembershipFirstDate
						? formatDate(orgPerson.personMetadata.dates?.athleteMembershipFirstDate)
						: '-'}
				</p>
			</Section>
			<Section title={'Data scadenza tesseramento'}>
				<p>
					{orgPerson.personMetadata.dates?.athleteMembershipExpirationDate
						? formatDate(orgPerson.personMetadata.dates?.athleteMembershipExpirationDate)
						: '-'}
				</p>
			</Section>
		</>
	);
};
