import { TObjTranche } from '@golee/gle-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getReceipt, Receipt } from '../../api/financial/entities/receipts';
import { getTranches } from '../../api/financial/entities/tranches';
import { useOrgPerson } from '../org-person/use-org-person';
import { useSeason } from '../season';
import { useProfiles } from '../profiles/use-profiles';

type TTrancheContext = {
	tranches: TObjTranche[];
	selectTranche: (trancheId: string) => void;
	selectedTranche?: TObjTranche;
};

export const TrancheContext = createContext<TTrancheContext | undefined>(undefined);

export const TrancheProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { selectedProfile } = useProfiles();
	const { orgPerson } = useOrgPerson();
	const { selectedSeason } = useSeason();

	const [tranches, setTranches] = useState<TObjTranche[]>();
	const [selectedTranche, setSelectedTranche] = useState<TObjTranche | undefined>();

	useEffect(() => {
		if (selectedProfile && selectedSeason) {
			void retrieveTranches();
		}
	}, [selectedProfile, selectedSeason]);

	const retrieveTranches = async () => {
		try {
			const resp = await getTranches({
				season: selectedSeason,
				ref_org_person: orgPerson.orgPersonId,
			});
			setTranches(resp.data);
		} catch (err) {
			//TODO: navigate to error page
		}
	};

	const selectTranche = (trancheId: string) => {
		const tranche = tranches?.find(t => t._id === trancheId);
		setSelectedTranche(tranche);
	};

	if (!tranches) return <>{props.fallback}</>;

	return (
		<TrancheContext.Provider
			value={{
				tranches,
				selectTranche,
				selectedTranche,
			}}>
			{props.children}
		</TrancheContext.Provider>
	);
};

export const useTranches = () => {
	const context = useContext(TrancheContext);

	if (!context) {
		throw new Error('useTranches must be used within TrancheProvider');
	}

	return { ...context, trancheToCashCount: context.tranches.filter(t => !t.cashed).length };
};

export const useReceipt = () => {
	const [receipt, setReceipt] = useState<Receipt>();
	const { selectedTranche } = useTranches();

	useEffect(() => {
		if (selectedTranche?.receipt?.id) {
			void fetchReceipt(selectedTranche.receipt.id);
		}
	}, [selectedTranche]);

	const fetchReceipt = async (id: string) => {
		try {
			const resp = await getReceipt({ id });
			setReceipt(resp.data);
		} catch (err) {}
	};

	return { receipt };
};
