import { OrgPerson } from '../../core/orgPerson/types';
import { Section } from '../section';

export const ContactsSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	return (
		<>
			<EmailSection orgPerson={orgPerson} />
			<PhoneSection orgPerson={orgPerson} />
		</>
	);
};

const EmailSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	if (!orgPerson.contacts?.primaryEmail) return <></>;

	return (
		<Section title={'Email di contatto per la società'}>
			<a
				href={`mailto:${orgPerson.contacts.primaryEmail}`}
				className="text-blue-600 hover:underline dark:text-blue-500">
				{orgPerson.contacts.primaryEmail}
			</a>
		</Section>
	);
};

const PhoneSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	if (!orgPerson.personMetadata?.contacts?.primaryPhone) return <></>;

	return (
		<Section title={'Telefono'}>
			<a
				href={`tel:${orgPerson.personMetadata.contacts.primaryPhone}`}
				className="text-blue-600 hover:underline dark:text-blue-500">
				{orgPerson.personMetadata.contacts.primaryPhone}
			</a>
		</Section>
	);
};
