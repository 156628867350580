import { Select } from '@gle/base-ui.inputs.select';
import { useI18n } from '@gle/providers.i18n';
import { SelectFieldProps } from '../../core/inputs/Select/Field';

export const TownsSelectField = (props: Omit<SelectFieldProps, 'options'>) => {
	const { messages } = useI18n();

	const onSearch = async (search: string) =>
		new Promise<any[]>(resolve => {
			if (!search || search.length < 3) {
			}

			getComuniOptions(0, 50, search).then(data => {
				resolve(
					data.options.map((t: any) => ({
						...t,
						value: t.label,
						province: t.provincia,
					}))
				);
			});
		});

	const getComuniOptions = async (start: number, limit: number, search: string) => {
		var _url = `https://europe-west6-golee-infra.cloudfunctions.net/comuni-paginator?start=${start}&limit=${limit}&search=${search}`;

		var _comuni: any = await fetch(_url);
		_comuni = await _comuni.json();
		const _hasMore = limit === _comuni.length;

		if (!search || search.toLowerCase().includes('est')) {
			if (start === 0) {
				_comuni.unshift({
					codiceCatastale: null,
					nome: 'Estero',
					provincia: null,
				});
			}
		}

		return {
			options: _comuni.map((c: any) => ({
				value: c.codiceCatastale,
				label: c.nome,
				provincia: c.provincia,
			})),
			hasMore: _hasMore,
			additional: { start: start + limit, limit: limit },
		};
	};

	return (
		<Select
			type="async"
			label={props.label}
			id="italian-town-list"
			loadingMessage={() => messages['loading']}
			cacheOptions
			isDisabled={props.isDisabled}
			defaultOptions
			loadOptions={onSearch}
			onChange={props.onChange}
			className={'select-creatable'}
			value={
				props.value
					? {
							label: props.value,
							value: props.value,
					  }
					: null
			}
			isClearable={props.isClearable}
			placeholder={props.placeholder || `Digita il comune...`}
		/>
	);
};
