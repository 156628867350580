import React, { HTMLAttributes } from 'react';

export const Section: React.FC<{ title?: string } & HTMLAttributes<HTMLDivElement>> = props => {
	return (
		<div className="border-solid border-b border-gray-100 pb-2 pt-5" onClick={props.onClick}>
			{props.title && <p className="uppercase text-gray-400 text-xs mb-2">{props.title}</p>}
			{props.children}
		</div>
	);
};
