import { TObjTranche } from '@golee/gle-types';
import client from '../client';

type Response<T> = {
	data: T;
};

const path = `tranche`;

const getTranches = async (params: { season: string; ref_org_person: string }): Promise<Response<TObjTranche[]>> => {
	try {
		const { season, ref_org_person } = params;
		const response = await client.get(`${path}`, {
			params: {
				ref_org_person,
				limit: 50,
			},
			headers: {
				'x-gle-current-season': params.season,
				'x-gle-selected-season': params.season,
			},
		});

		return {
			...response,
			data: response.data,
		};
	} catch (err) {
		throw err;
	}
};

export { getTranches };
