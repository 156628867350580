import React, { createContext, useEffect, useState } from 'react';
import { getIdentities } from '../../api/guardian/entities/organization';
import { TProfile } from '../../api/people/entities/profiles';
import { useBoolean } from '../../hooks/use-boolean';
import { useAuth } from '../auth';
import { addIdentityIdToApi, authenticateApi } from './authenticate-api';
import { Identity, loadIdentity } from './identity';

export type PermissionsContextType = {
	isLoading: boolean;
	identities: Identity[];
	onProfileChanged: (profile: TProfile) => void;
	identity?: Identity;
};

const getAllowedIdentityId = (identities: Identity[], profile: TProfile) => {
	return identities.find(
		identity => identity.role === 'organization_person' && identity.orgPersonId === profile.ref_org_person
	)?.identityId;
};

export const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const PermissionsProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const [isLoading, setIsLoading] = useBoolean(false);
	const [identities, setIdentities] = useState<Identity[]>();
	const { token, logout, clientId } = useAuth();

	const [identity, setIdentity] = useState<Identity>();

	useEffect(() => {
		console.log('permissions context init');
		void setupPermissions();
	}, []);

	const setupPermissions = async () => {
		setIsLoading(true);
		const permissions = await getIdentities(token);

		if (!permissions.length) {
			void logout();
			console.error('role-not-allowed');
		}
		setIdentities(permissions);
		authenticateApi(clientId, token);
		setIsLoading(false);
	};

	const onProfileChanged = async (profile: TProfile) => {
		if (!identities) return;

		const identityId = getAllowedIdentityId(identities, profile);
		if (!identityId) return;
		void addIdentityIdToApi(identityId);
		void setIdentity(await loadIdentity(identityId));
	};

	if (isLoading || !identities) {
		return <>{props.fallback}</>;
	}

	return (
		<PermissionsContext.Provider
			value={{
				isLoading,
				identities,
				onProfileChanged,
				identity,
			}}>
			{props.children}
		</PermissionsContext.Provider>
	);
};
