import client from '../client';

const path = 'files';
export type TPepaFileUploaded = {
	_id: string;
	deleted: boolean;
	file_name: string;
	magic_url: string;
	mime: string;
	public_url: string;
};

const uploadFileToPepa = ({
	formData,
	config,
}: {
	formData: FormData;
	config: any;
}): Promise<{
	data: TPepaFileUploaded;
}> => {
	return client.post(path, formData, config);
};

export { uploadFileToPepa };
