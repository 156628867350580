import { Card } from '@/components/card';
import { Heading } from '@gle/base-ui.typography.heading';
import { Divider } from '../../../components/divider';
import { useSponsors } from '../../../context/sponsors';

export const Sponsors = () => {
	const { primarySponsors, secondarySponsors } = useSponsors();

	return (
		<div className="pb-5">
			<Heading level={3} className="mb-5">
				I nostri sponsor
			</Heading>
			<div className={'flex flex-col gap-4'}>
				<div className={'flex flex-col gap-5'}>
					<p className="text-lg font-medium text-slate-500">Principali</p>
					<div className={'grid grid-cols-1 gap-5 md:grid-cols-2'}>
						{primarySponsors.map(s => (
							<a href={s.link} target={'_blank'}>
								<Card key={s.sponsorId} className={'flex flex-col items-center justify-center p-5'}>
									<img src={s.logoUrl} alt={s.name} className={'max-h-28'} />
								</Card>
							</a>
						))}
					</div>
				</div>
				<Divider />
				<div className={'flex flex-col gap-5'}>
					<p className="text-lg font-medium text-slate-500">Altri sponsor</p>
					<div className={'grid grid-cols-2 gap-5'}>
						{secondarySponsors.map(s => (
							<a href={s.link} target={'_blank'}>
								<Card key={s.sponsorId} className={'flex flex-col items-center justify-center p-5'}>
									<img src={s.logoUrl} alt={s.name} className={'max-h-14'} />
								</Card>
							</a>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
