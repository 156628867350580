import client from '../client';
import { Identity } from '../../../context/permissions/identity';

export const getIdentities = async (accessToken: string): Promise<Identity[]> => {
	try {
		const { data } = await client.get<Identity[]>(`organization/identities`, {
			headers: { Authorization: `Bearer ${accessToken}` },
		});
		return data;
	} catch (e) {
		throw e;
	}
};
