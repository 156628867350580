import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import moment from 'moment';
import { useEffect } from 'react';
import { useOrgPerson } from '../../../../../context/org-person/use-org-person';
import { OrgPerson } from '../../../../../core/orgPerson/types';
import { useCalendarEvents } from '../../../calendar/use-calendar-events';
import { useCalendarFilters } from '../../../calendar/use-calendar-filters';
import { EventsStatisticsFilters } from './events-statistics-filters';

export const StatisticsTab = () => {
	const { orgPerson } = useOrgPerson();

	return <Statistics orgPerson={orgPerson} />;
};

export const Statistics = (props: { orgPerson: OrgPerson }) => {
	const { from, to, type, onChangeFrom, onChangeTo, onChangeType, eventsType } = useCalendarFilters('month');

	const { loadStatistics: loadOrgPersonStatistics, statistics } = useCalendarEvents(
		props.orgPerson.orgPersonId,
		props.orgPerson.organizationId
	);

	const getColor = (type?: 'presence' | 'absence', count?: number) => {
		if (type === 'presence' && count) {
			return '#40B956';
		}
		if (type === 'absence' && count) {
			return '#D32127';
		}
		return '';
	};

	const calcPercentagePresence = () => {
		if (statistics?.terminatedEventsCount) {
			return `${((statistics?.presences.trueCount / statistics.terminatedEventsCount) * 100).toFixed(0)}%`;
		}
		return `0%`;
	};

	const loadStatistics = async () => {
		void loadOrgPersonStatistics(props.orgPerson.orgPersonId, {
			fromDate: moment(from).toISOString(),
			toDate: moment(to).toISOString(),
			type: type,
		});
	};

	useEffect(() => {
		if (!from || !to) return;
		void loadStatistics();
	}, [from, to, type]);
	return (
		<>
			<Heading level={4}>Presenze e statistiche</Heading>
			<EventsStatisticsFilters
				eventsType={eventsType}
				from={from}
				to={to}
				onChangeFrom={onChangeFrom}
				onChangeTo={onChangeTo}
				onChangeType={onChangeType}
			/>
			<div style={{ display: 'flex', gap: 20, marginTop: 20, marginBlock: 40 }}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
					<Text color="gray">Inviti</Text>
					<Text color="gray">Eventi terminati</Text>
					<Text color="gray">Presenze</Text>
					<Text color="gray">Assenze</Text>
					<Text color="gray">Percentuale presenze </Text>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
					<Text>{statistics?.eventsCount || 0}</Text>
					<Text>{statistics?.terminatedEventsCount || 0}</Text>
					<Text
						style={{
							color: getColor('presence', statistics?.presences.trueCount),
						}}>
						{statistics?.presences.trueCount || 0}
					</Text>
					<Text
						style={{
							color: getColor('absence', statistics?.presences.falseCount),
						}}>
						{statistics?.presences.falseCount || 0}
					</Text>
					<Text weight="bold">{calcPercentagePresence()}</Text>
				</div>
			</div>
		</>
	);
};
