import { useI18n } from '@gle/providers.i18n';
import { useConan } from '@golee/gle-conan-tracker';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Metadata } from '../../../../../api/people/entities/metadata';
import { GroupsSection, MembershipSection, SkeletonParagraph } from '../../../../../components';
import { CustomInputFields } from '../../../../../components/custom-fields/custom-input-fields';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import toast from '../../../../../components/toast/toast';
import { Flex } from '../../../../../core/@ui/flex';
import { useCustomFields } from '../../../../../hooks/CustomInputFields';
import { useMetadata } from '../../../../../hooks/use-metadata';
import { FormProps } from '../types';

export const SportForm = (props: FormProps) => {
	const { messages } = useI18n();

	const { trackEvent } = useConan();

	const { customFields } = useCustomFields();

	const { data, set, isLoading, isSaving } = useMetadata({
		orgPersonId: props.orgPerson.orgPersonId,
		season: props.orgPerson.season,
	});

	//TODO: refactor this
	if (isLoading) {
		return (
			<>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
					<SkeletonParagraph style={{ height: 30, marginBottom: 10 }} />
				</div>
			</>
		);
	}

	//TODO make dynamic validation schema based on custom fields
	const schema = Yup.object().shape({
		athlete: Yup.object().shape({
			technicalProfile: Yup.object().shape({
				'foot-n': Yup.number(),
			}),
		}),
	});

	const onSubmit = async (values: Metadata, set: any) => {
		await set(
			values,
			() => {
				toast.error(messages['save_error']);
			},
			() => {
				toast.success(messages['save_successful']);
			}
		);

		void trackEvent('family.orgPerson-changed-data-from-app', {
			section: 'sport',
		});
		props.onSave();
		props.onChangeEdit(false);
	};

	return (
		<Formik initialValues={{ ...data }} validationSchema={schema} enableReinitialize onSubmit={() => {}}>
			{formContext => {
				return (
					<Form>
						<SubmitButtons
							isEditing={props.isEditing}
							onChangeEdit={value => props.onChangeEdit(value)}
							isSaving={isSaving}
							onSubmit={() => {
								onSubmit(formContext.values, set);
							}}
						/>

						<GroupsSection orgPerson={props.orgPerson} />
						<MembershipSection orgPerson={props.orgPerson} />
						<Flex gap={10} flexDirection="column" marginBlock={10}>
							<CustomInputFields
								fields={customFields}
								formContext={formContext}
								isEditing={props.isEditing}
							/>
						</Flex>
					</Form>
				);
			}}
		</Formik>
	);
};
