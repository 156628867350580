import { EClubCompetitionLevel, ESport } from '@golee/gle-types';
import { Settings } from '../../../context/settings';
import { apiClient } from '../../client';

export type Organization = {
	_id: string;
	name: string;
	slug: string;
	legacy_id: number;
	foundation_year?: string;
	competition_level?: EClubCompetitionLevel;
	vat_number?: string;
	invoice_exchange_code?: string;
	federation_number?: string;
	logo_url?: string;
	signature_url?: string | null;
	contacts?: {
		tel?: string;
		email?: string;
	}[];
	links?: {
		web?: string;
		fb?: string;
		ig?: string;
		tw?: string;
		yt?: string;
	};
	colors?: {
		'1'?: string;
		'2'?: string;
		'3'?: string;
	};
	address?: {
		address?: string;
		postal_code?: string;
		town?: string;
		country?: string;
		region?: string;
		zone?: string;
	};
	affiliate?: {
		club_logo_url?: string;
		club_name?: string;
		club_link?: string;
	};
	sports?: ESport[];
};

export const getOrganization = async ({ organizationId }: { organizationId: string }) => {
	const { data: organization } = await apiClient('club').get<Organization>(`club/${organizationId}`);
	return organization;
};

type SeasonData = {
	currentSeason: string;
	seasons: string[];
};

export const getOrganizationSeason = ({ organizationId }: { organizationId: string }) => {
	return apiClient('club').get<SeasonData>(`organizations/${organizationId}/season`);
};

export const getOrganizationSettings = () => {
	return apiClient('club').get<Settings>(`settings/app`);
};
