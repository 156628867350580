import { Button } from '@/components/button';
import { EditIcon } from 'lucide-react';
import { Section } from '../section';
import { SubmitButtons, SubmitButtonsProps } from '../submit-buttons/submit-buttons';

type NameSectionProps = {
	name: string;
	surname: string;
	isRenaming: boolean;
	onChangeRenaming: (value: boolean) => void;
} & SubmitButtonsProps;

export const NameSection = (props: NameSectionProps) => {
	return (
		<Section>
			<div className="flex items-center gap-2">
				<p>
					{props.name} {props.surname}
				</p>
				{!props.isEditing && (
					<>
						{props.isRenaming ? (
							<SubmitButtons isEditing={props.isEditing} onSubmit={() => props.onChangeRenaming(false)} />
						) : (
							<Button variant={'secondary'} size={'icon'} onClick={() => props.onChangeRenaming(true)}>
								<EditIcon size={18} />
							</Button>
						)}
					</>
				)}
			</div>
		</Section>
	);
};
