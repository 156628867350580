import { apiClient } from '../../client';

export type Sponsor = {
	sponsorId: string;
	organizationId: string;
	name: string;
	category: 'PRIMARY' | 'SECONDARY';
	isVisibleInApp: boolean;
	isVisibleInSite: boolean;
	logoUrl?: string;
	link?: string;
};

export const getSponsors = async () => {
	const { data: sponsors } = await apiClient('club').get<Sponsor[]>(`sponsors`);
	return sponsors;
};

type SeasonData = {
	currentSeason: string;
	seasons: string[];
};

export const getOrganizationSeason = ({ organizationId }: { organizationId: string }) => {
	return apiClient('club').get<SeasonData>(`organizations/${organizationId}/season`);
};
