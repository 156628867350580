import { Text } from '@gle/base-ui.typography.text';
import {
	ArchiveIcon,
	BellIcon,
	CalendarIcon,
	ClipboardCheckIcon,
	CreditCardIcon,
	EuroIcon,
	HomeIcon,
	LogOutIcon,
	MenuIcon,
	RssIcon,
	SettingsIcon,
	UserIcon,
	UsersIcon,
} from 'lucide-react';
import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../context/auth';
import { useProfiles } from '../../context/profiles/use-profiles';
import { useReactNative } from '../../hooks/use-react-native';
import { PermissionV2, PermissionWrapper } from '../permission-wrapper/permission-wrapper';

enum EMenuSection {
	'HOME' = 'Home',
	'PROFILE' = 'Profilo',
	'PAYEMENTS' = 'Pagamenti',
	'DOCUMENTS' = 'Documenti',
	'PROFILES' = 'Scelta Profilo',
	'CALENDAR' = 'Calendario',
	'POSTS' = 'Bacheca',
	'AGREEMENTS' = 'Consensi',
	'NOTIFICATIONS' = 'Notifiche',
}

export const Menu = () => {
	const [isOpen, setIsOpen] = useState<boolean | undefined>();

	const onToggle = () => {
		if (isOpen) {
			onClose();
		} else {
			onOpen();
		}
	};

	const onOpen = () => {
		document.body.classList.add('stop-scrolling');
		setIsOpen(true);
	};

	const onClose = () => {
		document.body.classList.remove('stop-scrolling');
		setIsOpen(false);
	};

	return (
		<Wrapper>
			<MenuIcon onClick={onToggle} size={24} color={'var(--primary-color)'} />
			<MenuPage isOpen={isOpen} onItemSelected={onClose} />
		</Wrapper>
	);
};

const responsiveWidth = 1100 + 'px';

const Wrapper = styled.div`
	.menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: #fff;
		animation-duration: 0.3s;
	}

	@media only screen and (min-width: ${responsiveWidth}) {
		.menu {
			left: 0;
		}

		.menu-icon {
			display: none;
		}
	}

	@media only screen and (max-width: ${responsiveWidth}) {
		.menu {
			left: -100%;
			width: 100%;
			height: 100vh;
		}

		.slide-open {
			animation-name: slide-open;
			left: 0;
		}

		.slide-close {
			animation-name: slide-close;
			left: -100%;
		}

		@keyframes slide-open {
			0% {
				left: -100%;
			}
			100% {
				left: 0;
			}
		}

		@keyframes slide-close {
			0% {
				left: 0;
			}
			100% {
				left: -100%;
			}
		}
	}

	.item {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
		color: rgb(159, 69, 66);
		padding: 10px;
		margin-top: 5px;
	}

	.item.selected {
		background-color: rgb(255, 207, 205);
		border-radius: 10px;
	}

	.logout {
		& div {
			transform: rotate(180deg);
		}
	}
`;

export const MenuPage = (props: { isOpen?: boolean; onItemSelected: () => void }) => {
	const { logout } = useAuth();
	const { unselectProfile, profiles } = useProfiles();
	const { sendMessageToRN, isFromApp, isAppVersionGte } = useReactNative();

	return (
		<div
			className={`menu ${props.isOpen === true && 'slide-open'} ${props.isOpen === false && 'slide-close'}`}
			style={{ zIndex: 1, top: 64 }}>
			<div className={'flex flex-col p-5'}>
				{menu.map(item => (
					<PermissionWrapper permission={item.permission as PermissionV2} key={item.title}>
						<NavLink
							onClick={() => {
								props.onItemSelected();
							}}
							key={item.title}
							to={`/${item.path}`}
							className={({ isActive }) => (isActive ? 'selected ' : '') + 'item cursor-pointer'}>
							<div className={'w-5'}>{item.icon}</div>
							<Text weight={'medium'} style={{ color: 'rgb(159, 69, 66)' }}>
								{item.title}
							</Text>
						</NavLink>
					</PermissionWrapper>
				))}
			</div>
			<div className={'border-t border-solid border-gray-50 p-5'}>
				<NavLink
					onClick={() => {
						props.onItemSelected();
					}}
					key={'membership-card'}
					to={`/membership-card`}
					className={({ isActive }) => (isActive ? 'selected ' : '') + 'item cursor-pointer'}>
					<div className={'w-5'}>
						<CreditCardIcon />
					</div>
					<Text weight={'medium'} style={{ color: 'rgb(159, 69, 66)' }}>
						{'Tessera'}
					</Text>
				</NavLink>
				<NavLink
					onClick={() => {
						props.onItemSelected();
					}}
					key={'notifications'}
					to={`/notifications`}
					className={({ isActive }) => (isActive ? 'selected ' : '') + 'item cursor-pointer'}>
					<div className={'w-5'}>
						<BellIcon />
					</div>
					<Text weight={'medium'} style={{ color: 'rgb(159, 69, 66)' }}>
						{'Notifiche'}
					</Text>
				</NavLink>
				{profiles && (
					<div className={'item cursor-pointer'} onClick={unselectProfile}>
						<div className={'w-5'}>
							<UsersIcon />
						</div>
						<Text weight={'medium'} style={{ color: '#783B38' }}>
							{'Profili'}
						</Text>
					</div>
				)}
				<div
					className={'item cursor-pointer'}
					onClick={() => {
						if (isFromApp && isAppVersionGte('1.2.2')) {
							void sendMessageToRN('request-open-account-settings', {});
						} else {
							window.location.href = `${window._env_.REACT_APP_AUTH_APP_URL}settings`;
						}
					}}>
					<div className={'w-5'}>
						<SettingsIcon />
					</div>
					<Text weight={'medium'} style={{ color: '#783B38' }}>
						{'Impostazioni'}
					</Text>
				</div>

				<div className={'logout item cursor-pointer'} onClick={logout}>
					<div className={'w-5'}>
						<LogOutIcon />
					</div>
					<Text weight={'medium'} style={{ color: '#783B38' }}>
						{'Logout'}
					</Text>
				</div>
			</div>
		</div>
	);
};

const menu: MenuItem[] = [
	{ title: EMenuSection.HOME, path: 'home', icon: <HomeIcon /> },
	{ title: EMenuSection.PROFILE, path: 'profile', icon: <UserIcon /> },
	{ title: EMenuSection.PAYEMENTS, path: 'payments', icon: <EuroIcon /> },
	{ title: EMenuSection.DOCUMENTS, path: 'documents', icon: <ArchiveIcon /> },
	{ title: EMenuSection.CALENDAR, path: 'calendar', icon: <CalendarIcon /> },
	{ title: EMenuSection.POSTS, path: 'posts', icon: <RssIcon />, permission: 'feed:read' },
	{ title: EMenuSection.AGREEMENTS, path: 'agreements', icon: <ClipboardCheckIcon />, permission: 'agreements:read' },
];

export type MenuItem = {
	title: string;
	path: string;
	icon: ReactNode;
	permission?: PermissionV2;
};
