import { Loader } from '@gle/base-ui.loader';
import { ChangeEvent, useRef, useState } from 'react';
import { uploadFileToPepa } from '../../api/pepa/entities/pepa';
import { useBoolean } from '../../hooks/use-boolean';
import { useReactNative } from '../../hooks/use-react-native';

export const ImageUploader = ({
	url,
	style,
	onChange,
}: {
	url?: string;
	style?: React.CSSProperties;
	onChange?: (url?: string) => void;
}) => {
	const [file, setFile] = useState<string | undefined>(url);
	const [isLoading, setIsLoading] = useBoolean();
	const { isFromApp, isAppVersionGte, sendMessageToRN, hasPermissionCamera } = useReactNative();

	const ref = useRef<any>();

	const onChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];

			let formData = new FormData();
			formData.append('file', file);
			const config = {
				onUploadProgress: function () {
					setIsLoading(true);
				},
			};
			const resp = await uploadFileToPepa({ formData, config });
			setIsLoading(false);
			setFile(resp.data.magic_url);
			onChange?.(resp.data.magic_url);
		}
	};

	return (
		<div
			style={{
				width: 120,
				height: 120,
				padding: 10,
				backgroundColor: '#f5f5f5',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 10,
				border: '1px dashed var(--gray-lighter-color)',
				...style,
			}}>
			{isLoading ? (
				<Loader />
			) : (
				<div
					style={{
						width: 100,
						height: 100,
						borderRadius: '10px',
						backgroundImage: `url("${file}")`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
					onClick={() => {
						if (isFromApp && isAppVersionGte('1.2.2') && !hasPermissionCamera) {
							void sendMessageToRN('request-camera-permission', {});
						}

						ref.current.click();
					}}>
					<input
						type="file"
						accept="image/*"
						onChange={onChangeImage}
						style={{ visibility: 'hidden' }}
						ref={ref}
					/>
				</div>
			)}
		</div>
	);
};
