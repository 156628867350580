import { ErrorMessage, FieldConfig, useField } from 'formik';
import styled from 'styled-components';

import { Input, InputProps } from '@gle/base-ui.inputs.input';
import { Text } from '@gle/base-ui.typography.text';
import { useUpdatedFieldProps } from '../use-updated-field-props';

export type InputFieldProps = FieldConfig<any> & {
	warning?: string | undefined;
	hint?: string | undefined;
	description?: string | undefined;
} & Omit<InputProps, 'onFocus' | 'value'>;

type ReadOnlyInputFieldProps = Pick<
	InputFieldProps,
	'label' | 'className' | 'style' | 'warning' | 'hint' | 'type' | 'checkPasswordStrength'
> & {
	value?: string;
};

const Wrapper = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	flex-direction: column;
	column-gap: 3px;
	row-gap: 3px;
	gap: 3px;

	&.h-auto {
		height: auto;
	}

	&.password-strength-enabled {
		height: 90px;
	}

	.form-field-error {
		color: var(--danger-color);
		font-size: var(--xs-size);

		&.password-strength-enabled {
			position: relative;
			top: -5px;
		}
	}

	&.error {
		label {
			color: var(--danger-color) !important;
		}
		input {
			border-color: var(--danger-color) !important;
		}
	}

	&.warning {
		input {
			border-color: var(--warning-color);
		}
	}
`;

const InputField = ({ onChange, onBlur, ..._props }: InputFieldProps) => {
	const [{ onBlur: onFieldBlur, ...field }, meta, helpers] = useField(_props);
	const props = useUpdatedFieldProps<InputFieldProps>(_props, 'input');

	return (
		<Wrapper
			style={props.style}
			className={`${meta.touched && meta.error ? 'error' : ''} ${props.type === 'textarea' ? 'h-auto' : ''} ${
				props.checkPasswordStrength ? 'password-strength-enabled' : ''
			} ${props.warning ? 'warning' : ''} ${props.className ? props.className : ''}
			`}>
			<Input
				{...props}
				{...field}
				onBlur={e => {
					onFieldBlur?.(e);
					onBlur?.(e);
				}}
				onChange={e => {
					helpers.setValue(e);
					onChange?.(e);
				}}
			/>

			{meta.error ? (
				<ErrorMessage
					name={props.name}
					component="div"
					className={`form-field-error ${
						props.checkPasswordStrength && field.value ? 'password-strength-enabled' : ''
					}`}
				/>
			) : (
				<>
					{props.warning && (
						<Text color="warning-dark" size="xs" className="form-field-warning">
							{props.warning}
						</Text>
					)}
					{props.hint && (
						<Text color="accent-dark" size="xs" className="form-field-hint">
							{props.hint}
						</Text>
					)}
					{props.description && (
						<Text color="gray" size="xs" className="form-field-description">
							{props.description}
						</Text>
					)}
				</>
			)}
		</Wrapper>
	);
};

export default InputField;
