import { OrgPerson } from '../../../core/orgPerson/types';
import client from '../client';

export type TProfile = {
	_id: string;
	name: string;
	surname: string;
	role: 'organization_admin' | 'organization_owner' | 'organization_person';
	email: string;
	ref_organization: string;
	ref_user: string;
	ref_org_person: string;
};

export const getProfiles = async (): Promise<TProfile[]> => {
	const resp = await client.get<TProfile[]>('/profiles/mine');
	return resp.data.filter(profile => profile.role === 'organization_person');
};

export const renameOrgPerson = async (payload: Pick<OrgPerson, 'orgPersonId' | 'name' | 'surname' | 'season'>) => {
	return client.post<void>(
		`org-people/cmd/rename`,
		{
			...payload,
		},
		{
			headers: {
				'x-gle-current-season': payload.season,
				'x-gle-selected-season': payload.season,
			},
		}
	);
};
