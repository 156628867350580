import { apiClient } from '../../client';

export type AvailablePaymentMethod = 'card' | 'klarna' | 'sepa_debit';

export type PaymentInfo = {
	denomination: string;
	clubLogo?: string;
	clubName: string;
	reason: string;
	amount: number;
	fee: number;
	cashed: boolean;
	availablePaymentMethods: AvailablePaymentMethod[];
	organizationId: string;
	trancheId: string;
};

export const getPaymentInfo = async ({
	organizationId,
	trancheId,
}: {
	organizationId: string;
	trancheId: string;
}): Promise<PaymentInfo> => {
	try {
		const response = await apiClient('payments').post(
			`payment-url/payment-info/organization/${organizationId}/tranche/${trancheId}`
		);

		return response.data;
	} catch (e) {
		throw e;
	}
};

export const getCheckoutLink = async ({
	organizationId,
	trancheId,
	paymentMethod,
}: {
	organizationId: string;
	trancheId: string;
	paymentMethod: AvailablePaymentMethod;
}): Promise<{ url: string }> => {
	try {
		const response = await apiClient('payments').post(
			`payment-url/checkout-link/organization/${organizationId}/tranche/${trancheId}`,
			{
				callbackUrl: window.location.href,
				paymentMethod,
			}
		);

		return response.data;
	} catch (e) {
		throw e;
	}
};
