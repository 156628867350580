import { Button } from '@/components/button';
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from '@/components/drawer';
import { useConan } from '@golee/gle-conan-tracker';
import { DownloadIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { sendInstantNotification } from '../../../api/hermes/entities/hermes';
import { getPrintTemplates, TPrintTemplate } from '../../../api/prints/entities/prints';
import toast from '../../../components/toast/toast';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { MimeTypeIcon } from './mime-type-icon';

export const RequestDocument = () => {
	const { orgPerson } = useOrgPerson();
	const { trackEvent } = useConan();

	const [printTemplates, setPrintTemplates] = useState<TPrintTemplate[]>([]);
	const [selectedDocument, setSelectedDocument] = useState<TPrintTemplate>();
	const [requestDialogOpen, setRequestDialogOpen] = useState<boolean>(false);

	const retrievePrintTemplates = async () => {
		const resp = await getPrintTemplates();
		setPrintTemplates(resp.data);
	};

	useEffect(() => {
		retrievePrintTemplates();
	}, []);

	useEffect(() => {
		if (!requestDialogOpen) {
			setSelectedDocument(undefined);
		}
	}, [requestDialogOpen]);

	const onRequestDocument = async () => {
		if (selectedDocument && orgPerson) {
			setRequestDialogOpen(false);
			await sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
				occurrenceType: 'DocumentRequestedByOrgPerson',
				ownerType: 'OrgPersonDocuments',
				metadata: {
					documentName: selectedDocument.label,
				},
				origin: 'user',
			});
			toast.success(`${selectedDocument.label} richiesto`);
			trackEvent('family.document-requested', { document_label: selectedDocument.label });
		}
	};

	const isDocumentSelected = (id?: string) => {
		if (!id) return false;
		return selectedDocument?._id === id;
	};

	return (
		<div>
			<Drawer open={requestDialogOpen} onOpenChange={setRequestDialogOpen}>
				<DrawerTrigger>
					<Button variant={'secondary'}>
						<DownloadIcon size={18} className="mr-2" /> Richiedi
					</Button>
				</DrawerTrigger>
				<DrawerContent>
					<DrawerHeader>
						<DrawerTitle>Richiedi documento alla società</DrawerTitle>
						<DrawerDescription>Seleziona quale documento vuoi richiedere</DrawerDescription>
					</DrawerHeader>

					<div className="p-4">
						<div className="flex flex-col gap-2">
							{printTemplates.map(pt => (
								<div
									key={pt._id}
									onClick={() => setSelectedDocument(pt)}
									className={`flex cursor-pointer items-center justify-start gap-3 rounded-md px-2 py-2 ${
										isDocumentSelected(pt._id)
											? 'border border-solid border-blue-100 bg-blue-50'
											: ''
									}`}>
									<div className={'w-6'}>
										<MimeTypeIcon mimeType={'application/pdf'} />
									</div>
									<p className={`text-sm ${isDocumentSelected(pt._id) ? 'font-medium' : ''}`}>
										{pt.label}
									</p>
								</div>
							))}
							<div className={'mt-4 flex flex-col'}>
								<Button disabled={!selectedDocument} onClick={onRequestDocument}>
									Richiedi
								</Button>
							</div>
						</div>
					</div>
				</DrawerContent>
			</Drawer>
		</div>
	);
};
