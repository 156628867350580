import { isValidDate } from '@gle/base-ui.inputs.input-date';
import moment from 'moment';
import * as yup from 'yup';

export const metadataDatesValidationSchema = (messages: { [key: string]: string }) => {
    return yup.object().shape({
        birthdate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        blsdCertificateExpirationDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        medicalExaminationExpirationDate: dateValidationSchema(
            messages,
            moment.ISO_8601
        ).nullable(),
        medicalExaminationScheduledDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        athleteMembershipFirstDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        athleteMembershipExpirationDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        technicalStaffMembershipExpirationDate: dateValidationSchema(
            messages,
            moment.ISO_8601
        ).nullable(),
        managerialStaffMembershipExpirationDate: dateValidationSchema(
            messages,
            moment.ISO_8601
        ).nullable(),
        identityDocumentExpirationDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
        residencePermitExpirationDate: dateValidationSchema(messages, moment.ISO_8601).nullable(),
    });
};

export const dateValidationSchema = (
    messages: { [key: string]: string },
    dateFormat?: string | moment.MomentBuiltinFormat
) => {
    return yup.mixed().test('date', messages['invalid_date'], value => {
        return value ? isValidDate(value, dateFormat as any as string) : true;
    });
};