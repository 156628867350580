import { Button } from '@/components/button';
import { OrgPersonConsentStatus } from './types';

export const ConsentNotAnswered = (props: {
	onChange: (status: OrgPersonConsentStatus) => void;
	isLoading?: boolean;
}) => {
	return (
		<div className="flex items-center justify-end gap-4">
			<Button size="sm" variant={'secondary'} onClick={() => props.onChange('given')} isLoading={props.isLoading}>
				Accetto
			</Button>
			<Button size="sm" variant={'secondary'} onClick={() => props.onChange('denied')} disabled={props.isLoading}>
				Nego
			</Button>
		</div>
	);
};
