import { OrgPerson } from '../../core/orgPerson/types';
import { Section } from '../section';

export const MembershipNumberSection = ({ orgPerson }: { orgPerson: OrgPerson }) => {
	const membershipNumber = orgPerson.metadata.athlete?.technicalProfile?.registrationNumber;

	if (!membershipNumber) return <Section title={'Matricola / Tessera'}>-</Section>;

	return (
		<Section title={'Matricola / Tessera'}>
			<p>{membershipNumber}</p>
		</Section>
	);
};
