import { Text } from '@gle/base-ui.typography.text';
import { useI18n } from '@gle/providers.i18n';
import InputField from './Field';

export type InputEmailProps = {
	isValid: () => boolean;
	onChange: (value?: string) => void;
	value?: string;
	readOnly?: boolean;
	name?: string;
};
export const InputEmail = (props: InputEmailProps) => {
	const { messages } = useI18n();
	return (
		<>
			<div style={{ marginBottom: 0 }}>
				<InputField
					name={props.name || ''}
					readOnly={props.readOnly}
					id="email-input"
					label={'Email di contatto per la società'}
					lowerCase
					value={props.value}
					labelStyle={!props.isValid() ? { color: 'var(--danger-color)' } : undefined}
					inputStyle={!props.isValid() ? { borderColor: 'var(--danger-color)' } : undefined}
					onChange={props.onChange}
				/>
				{!props.isValid() && (
					<Text size="xs" color="danger" style={{ marginTop: 5 }}>
						{messages['email_not_valid']}
					</Text>
				)}
			</div>
		</>
	);
};
