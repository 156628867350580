import { DocumentSection } from '../../../../../components/org-person-sections/document-section';
import { IbanSection } from '../../../../../components/org-person-sections/iban-section';
import { SchoolSection } from '../../../../../components/org-person-sections/school-section';
import { SubmitButtons } from '../../../../../components/submit-buttons/submit-buttons';
import { ViewProps } from '../types';

export const OtherView = (props: ViewProps) => {
	if (!props.orgPerson) return <></>;

	return (
		<>
			<SubmitButtons isEditing={props.isEditing} onChangeEdit={value => props.onChangeEdit(value)} />
			<DocumentSection orgPerson={props.orgPerson} />
			<SchoolSection orgPerson={props.orgPerson} />
			<IbanSection orgPerson={props.orgPerson} />
		</>
	);
};
