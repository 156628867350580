import { useEffect, useState } from 'react';
import { Metadata, MetadataParams, getMetadata, setMetadata } from '../api/people/entities/metadata';

export const useMetadata = (params: MetadataParams) => {
	const [data, setData] = useState<Metadata>({});

	const [isSaving, setIsSaving] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const get = async () => {
		setIsLoading(true);
		try {
			const { data } = await getMetadata(params);
			setData(data);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (params.orgPersonId) {
			get();
		}
	}, [params.orgPersonId]);

	const set = async (payload: Metadata, onError?: (err?: any) => void, onComplete?: () => void) => {
		if (!params.orgPersonId) {
			return;
		}

		let body = payload;

		delete body.id;
		delete body.orgPersonId;

		setIsSaving(true);

		try {
			const { data } = await setMetadata(params, body);

			setData(data);
			onComplete?.();
		} catch (err) {
			onError?.();
		} finally {
			setIsSaving(false);
		}
	};

	return { data, set, isSaving, isLoading, _getMetadata: get };
};
