import { useState } from 'react';
import { getPaymentInfo, PaymentInfo } from '../../../api/payments/entities/link';

export const usePaymentInfo = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
	const [error, setError] = useState<boolean>(false);

	const loadPaymentInfo = async (organizationId: string, trancheId: string) => {
		setIsLoading(true);

		try {
			const info = await getPaymentInfo({ organizationId, trancheId });
			setPaymentInfo(info);
		} catch (err) {
			setError(true);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		loadPaymentInfo,
		isLoading,
		error,
		paymentInfo,
	};
};
