import client from '../client';

export type TInvite = {
	invite: {
		inviteId: string;
		publicLink: string;
		userRole: string;
	};
	email: string;
	name: string;
	ref_org_person: string;
	ref_organization: string;
	ref_user: string;
	role: string;
	surname: string;
	_id: string;
};

export const getPendingInvites = async (): Promise<TInvite[]> => {
	const resp = await client.get<TInvite[]>('/invites/mine');
	return resp.data;
};

export const acceptInvite = async (uuid: string) => {
	await client.post(`/invites/${uuid}/accept-invite-cmd`);
};
