import { Badge } from '@/components/badge';
import { Button } from '@/components/button';
import { Input } from '@gle/base-ui.inputs.input';
import { Heading } from '@gle/base-ui.typography.heading';
import { useConan } from '@golee/gle-conan-tracker';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { addDocument, getDocuments, TDocument } from '../../../api/documents/entities/documents';
import { sendInstantNotification } from '../../../api/hermes/entities/hermes';
import { TPepaFileUploaded } from '../../../api/pepa/entities/pepa';
import { EmptyList } from '../../../components';
import toast from '../../../components/toast/toast';
import UploaderOnPepa from '../../../components/uploader/document-uploader';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { MimeTypeIcon } from './mime-type-icon';
import { RequestDocument } from './request-document';

export const Documents = () => {
	const { orgPerson } = useOrgPerson();
	const { trackEvent } = useConan();

	const [docs, setDocs] = useState<TDocument[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fileUploaded, setFileUploaded] = useState<TPepaFileUploaded>();

	const retrieveDocuments = async (orgPersonId: string) => {
		setIsLoading(true);
		const resp = await getDocuments('org_person', orgPersonId);
		setDocs(resp.data);
		setIsLoading(false);
	};

	useEffect(() => {
		if (orgPerson) {
			retrieveDocuments(orgPerson.orgPersonId);
		}
	}, [orgPerson]);

	const onUploadedFile = async () => {
		if (!orgPerson) {
			return;
		}

		if (!fileUploaded) {
			return;
		}

		try {
			await addDocument({
				url: fileUploaded.public_url,
				name: fileUploaded.file_name,
				mimeType: fileUploaded.mime,
				ref_entity: orgPerson.orgPersonId,
			});

			setFileUploaded(undefined);

			await retrieveDocuments(orgPerson.orgPersonId);

			try {
				await sendInstantNotification(orgPerson.orgPersonId, orgPerson.organizationId, {
					occurrenceType: 'DocumentAttachedByOrgPerson',
					ownerType: 'OrgPersonDocuments',
					metadata: {
						documentName: fileUploaded.file_name,
						documentUrl: fileUploaded.public_url,
					},
					origin: 'system',
				});
			} catch (e: any) {
				console.error(`Error sending notification: ${e.message}`);
			}

			trackEvent('family.document-uploaded', { file_name: fileUploaded.file_name });
		} catch (e: any) {
			toast.error(`Error uploading file: ${e.message}`);
		}
	};

	return (
		<div>
			<Heading level={3} className="mb-5">
				Documenti
			</Heading>
			<div className={'mb-10'}>
				{fileUploaded ? (
					<div className={'flex gap-5'}>
						<Input
							value={fileUploaded.file_name}
							onChange={file_name => setFileUploaded({ ...fileUploaded, file_name })}
						/>
						<Button onClick={onUploadedFile}>Invia</Button>
					</div>
				) : (
					<div className={'flex gap-5'}>
						<UploaderOnPepa onUploadedFile={setFileUploaded} />
						<RequestDocument />
					</div>
				)}
			</div>
			{!docs.length && !isLoading ? (
				<EmptyList />
			) : (
				<div className={'flex flex-col pt-2'}>
					{docs.map(doc => (
						<DocCard doc={doc} key={doc._id} />
					))}
				</div>
			)}

			<Outlet />
		</div>
	);
};

const DocCard = ({ doc: d }: { doc: TDocument }) => {
	return (
		<div>
			<div className="mb-1 flex justify-end gap-1">
				{!d.seenFromOrgPerson && <Badge variant={'secondary'}>Nuovo</Badge>}

				{d.signature?.status === 'pending' && <Badge variant={'warning'}>Da firmare</Badge>}

				{d.signature?.status === 'completed' && <Badge variant={'success'}>Firmato</Badge>}
			</div>

			<Link
				className={
					'mb-3 mt-1 flex items-center gap-5 border-b border-solid border-gray-100 pb-3 hover:cursor-pointer'
				}
				to={`/documents/${d._id}`}
				state={{ document: d }}
				key={d._id}>
				<div>
					<MimeTypeIcon mimeType={d.mimeType} />
				</div>

				<div className="flex flex-col gap-1">
					<p className="text-sm">{d.name}</p>

					<p className="text-xs text-gray-400">
						{d.createdAt ? DateTime.fromISO(d.createdAt).toFormat('HH:mm dd/MM/yyyy') : ''}
					</p>
				</div>
			</Link>
		</div>
	);
};
