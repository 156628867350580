import { Button } from '@/components/button';
import { Heading } from '@gle/base-ui.typography.heading';
import { Text } from '@gle/base-ui.typography.text';
import { useNavigate } from 'react-router-dom';
import { ErrorIcon } from '../../assets';
import { MainLayout } from '../../components';

export const NotFound = () => {
	const navigate = useNavigate();

	return (
		<MainLayout>
			<div style={{ textAlign: 'center' }}>
				<ErrorIcon />

				<Heading level={3} style={{ marginBottom: 20 }}>
					Oops!
				</Heading>

				<Text>La pagina che stai cercando non esiste.</Text>

				<Button onClick={() => navigate('/')} className="mt-5" variant={'secondary'}>
					Torna alla home page
				</Button>
			</div>
		</MainLayout>
	);
};
