import { useState } from 'react';
import { getPaymentInfo, PaymentInfo } from '../api/payments/entities/link';
import { getOrganizationInfo } from '../api/payments/entities/organization';

export const usePaymentInfo = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
	const [error, setError] = useState<boolean>(false);

	const loadPaymentInfo = async (organizationId: string, trancheId: string) => {
		try {
			setIsLoading(true);

			const orgInfo = await getOrganizationInfo();
			if (!!orgInfo.stripe_connect_account) {
				const info = await getPaymentInfo({ organizationId, trancheId });
				setPaymentInfo(info);
			}
		} catch (err) {
			setError(true);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		loadPaymentInfo,
		isLoading,
		error,
		paymentInfo,
	};
};
