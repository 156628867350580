import { ReactNode } from 'react';
import { useMobileOs } from '../../hooks/use-mobile-os';
import { useReactNative } from '../../hooks/use-react-native';
import { Content } from './content';
import { Header } from './header';
import { Outlet } from 'react-router-dom';

export const MenuLayout = () => {
	const isDownloadAppBannerVisible = false; //!isFromApp && (os === 'android' || os === 'ios');

	return (
		<div className={'h-[100vh]'}>
			<Header hideNavigation={false} isDownloadAppBannerVisible={isDownloadAppBannerVisible} />
			<Content isDownloadAppBannerVisible={isDownloadAppBannerVisible}>
				<Outlet />
			</Content>
		</div>
	);
};

export const LayoutBase = (props: { children: ReactNode }) => {
	const { isFromApp } = useReactNative();
	const os = useMobileOs();

	const isDownloadAppBannerVisible = !isFromApp && (os === 'android' || os === 'ios');

	return (
		<div>
			<div>
				<Header hideNavigation isDownloadAppBannerVisible={isDownloadAppBannerVisible} />
				<Content isDownloadAppBannerVisible={isDownloadAppBannerVisible}>{props.children}</Content>
			</div>
		</div>
	);
};
