import { FormikContextType } from "formik";
import { TOrgPerson } from "../../api/people/entities/TOrgPerson";
import { Metadata } from "../../api/people/entities/metadata";

export type OrgPersonFormProps = {
    /**
     * Org person in question.
     */
    orgPerson?: TOrgPerson;

    /**
     * Force readOnly state to all fields.
     */
    readOnly?: boolean;

};

export type OrgPersonFormFieldProps = {
    formContext: FormikContextType<Metadata>;
    readOnly?: boolean;
};

export enum ContactType {
    primaryEmail = 'primaryEmail',
}