import styled from 'styled-components';

const Wrap = styled.div`
	&.skeleton {
		background-color: rgba(0, 0, 0, 0.05);
		transform: translate3d(0px, 0px, 0px);
		position: relative;
		border-radius: 10px;
		height: 20px;
		overflow: hidden;
		width: 100%;
	}

	&.title + .row {
		margin-top: 10px;
	}

	&.row {
		height: 15px;
		margin-top: 5px;
	}

	&.list-item {
		height: 50px;
		margin-top: 10px;
	}

	&.list-item-image {
		height: 50px;
		width: 60px;
		border-radius: 10px;
		margin-right: 10px;
		margin-top: 10px;
	}

	&.image {
		height: 250px;
		margin: 0 auto;
		margin-bottom: 30px;
		width: 250px;
	}

	@keyframes bg-move {
		0% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(100%);
		}
	}

	&.skeleton > div {
		transform: translate3d(0px, 0px, 0px);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.075) 30%,
			rgba(0, 0, 0, 0.075) 40%,
			rgba(0, 0, 0, 0) 80%
		);
		animation: bg-move 1.5s -0.75s ease infinite;
	}
`;

export const SkeletonTitle = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<Wrap className="skeleton title" style={style}>
			<div></div>
		</Wrap>
	);
};

export const SkeletonParagraph = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<>
			<Wrap className="skeleton row" style={style}>
				<div></div>
			</Wrap>
			<Wrap className="skeleton row" style={style}>
				<div></div>
			</Wrap>
			<Wrap className="skeleton row" style={style}>
				<div></div>
			</Wrap>
		</>
	);
};

export const SkeletonImage = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<Wrap className="skeleton image" style={style}>
			<div></div>
		</Wrap>
	);
};

export const SkeletonListItem = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<div style={{ display: 'flex' }}>
			<Wrap className="skeleton list-item-image" style={style}>
				<div></div>
			</Wrap>
			<Wrap className="skeleton list-item" style={style}>
				<div></div>
			</Wrap>
		</div>
	);
};

export const SkeletonButton = ({ style = {} }: { style?: React.CSSProperties }) => {
	return (
		<div style={{ display: 'flex' }}>
			<Wrap className="skeleton list-item" style={style}>
				<div></div>
			</Wrap>
		</div>
	);
};
