import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';
import { preloadAssets } from './preloadAssets';
import reportWebVitals from './reportWebVitals';

declare global {
	interface Window {
		_env_: any;
	}
}

preloadAssets();

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
