import { useNavigate } from 'react-router-dom';

export const NavigateBack = ({ color = 'var(--white-color)', ...props }: { onClick?: () => void; color?: string }) => {
	const navigate = useNavigate();

	return (
		// <svg
		//   onClick={() => (props.onClick ? props.onClick() : navigate(-1))}
		//   style={{ marginRight: 10, cursor: "pointer" }}
		//   xmlns="http://www.w3.org/2000/svg"
		//   height={25}
		//   fill="none"
		//   viewBox="0 0 24 24"
		//   stroke={color}
		// >
		//   <path
		//     strokeLinecap="round"
		//     strokeLinejoin="round"
		//     strokeWidth={3}
		//     d="M15 19l-7-7 7-7"
		//   />
		// </svg>

		<p
			onClick={() => navigate(-1)}
			className="font-medium text-zinc-500 mb-1 cursor-pointer hover:underline text-sm">
			Indietro
		</p>
	);
};
