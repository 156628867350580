import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingLayout } from '../components';
import { MenuLayout } from '../components/layout/layout';
import { AuthProvider, useAuth } from '../context/auth';
import { CacheProvider } from '../context/cache';
import { I18NProvider } from '../context/i18n';
import { LocationsProvider } from '../context/locations/context';
import { OrgPersonProvider } from '../context/org-person/org-person-context';
import { PermissionsProvider } from '../context/permissions/permissions-context';
import { ProfileProvider } from '../context/profiles/profiles-context';
import { useProfiles } from '../context/profiles/use-profiles';
import { SeasonProvider } from '../context/season';
import { SettingsProvider } from '../context/settings';
import { SponsorsProvider } from '../context/sponsors';
import { ConanPageTracker, TrackingProvider } from '../context/tracking';
import { TrancheProvider } from '../context/tranches';
import {
	Calendar,
	Consents,
	DocumentPage,
	Documents,
	MembershipCardPage,
	NotFound,
	Pay,
	Payments,
	Posts,
	Profiles,
	TrancheDetail,
} from '../pages';
import { Home } from '../pages/private/home';
import { Notifications } from '../pages/private/notifications';
import { Profile } from '../pages/private/profile';
import { RoleNotAllowedPage } from '../pages/private/role-not-allowed';
import { Sponsors } from '../pages/private/sponsors';

export const AppRouter = () => {
	return (
		<I18NProvider fallback={<LoadingLayout />}>
			<Routes>
				<Route element={<WithAuthProviders />}>
					<Route element={<WithAuthGuard />}>
						<Route path="/" element={<Navigate to={'/profiles'} />} />
						<Route path="/profiles" element={<Profiles />} />
						<Route path="/role-not-allowed" element={<RoleNotAllowedPage />} />
						<Route element={<WithSelectedProfileGuard />}>
							<Route element={<WithSelectedProfileProviders />}>
								<Route element={<MenuLayout />}>
									<Route path="/home" element={<Home />} />
									<Route path="/profile" element={<Profile />} />
									<Route path="/payments" element={<Payments />} />
									<Route path="/documents" element={<Documents />} />
									<Route path="/documents/:documentId" element={<DocumentPage />} />
									<Route path="/tranche-detail" element={<TrancheDetail />} />
									<Route path="/calendar" element={<Calendar />} />
									<Route path="/posts" element={<Posts />} />
									<Route path="/agreements" element={<Consents />} />
									<Route path="/notifications" element={<Notifications />} />
									<Route path="/sponsors" element={<Sponsors />} />
									<Route path="/membership-card" element={<MembershipCardPage />} />
									<Route path="*" element={<NotFound />} />
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>

				<Route path="/pay/:encoded" element={<Pay />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
			<ToastContainer />
		</I18NProvider>
	);
};

const WithAuthProviders = () => {
	return (
		<AuthProvider fallback={<LoadingLayout />}>
			<PermissionsProvider fallback={<LoadingLayout />}>
				<ProfileProvider fallback={<LoadingLayout />}>
					<Outlet />
				</ProfileProvider>
			</PermissionsProvider>
		</AuthProvider>
	);
};

const WithAuthGuard = () => {
	const { isAuthenticated } = useAuth();

	if (!isAuthenticated) return <Navigate to={'/login'} />;

	return <Outlet />;
};

const WithSelectedProfileGuard = () => {
	const { selectedProfile } = useProfiles();
	if (!selectedProfile) return <Navigate to={'/profiles'} />;
	return <Outlet />;
};

const WithSelectedProfileProviders = () => {
	return (
		<SeasonProvider fallback={<LoadingLayout />}>
			<OrgPersonProvider fallback={<LoadingLayout />}>
				<TrackingProvider>
					<TrancheProvider fallback={<LoadingLayout />}>
						<SettingsProvider>
							<SponsorsProvider>
								<LocationsProvider>
									<CacheProvider>
										<ConanPageTracker />
										<Outlet />
									</CacheProvider>
								</LocationsProvider>
							</SponsorsProvider>
						</SettingsProvider>
					</TrancheProvider>
				</TrackingProvider>
			</OrgPersonProvider>
		</SeasonProvider>
	);
};
