import { apiClient } from '../../client';

export const getOrganizationInfo = async (): Promise<{
	_id: string;
	ref_organization: string;
	club_legacy_id: number;
	stripe_customer?: string;
	stripe_connect_account?: string;
}> => {
	try {
		const response = await apiClient('payments').get(`info/organization`);
		return response.data;
	} catch (e) {
		throw new Error('organization-info-not-available');
	}
};
