import React, { createContext, useEffect, useState } from 'react';
import { acceptInvite, getPendingInvites, TInvite } from '../../api/people/entities/invites';
import { getProfiles, TProfile } from '../../api/people/entities/profiles';
import { useReactNative } from '../../hooks/use-react-native';
import { usePermissions } from '../permissions/use-permissions';
import { useNavigate } from 'react-router-dom';

type ProfileContextType = {
	profiles?: TProfile[];
	pendingInvites?: TInvite[];
	selectedProfile?: TProfile;
	chooseProfile: (profile: TProfile) => void;
	unselectProfile: () => void;
	acceptInvite: (uuid: string) => void;
};

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { sendMessageToRN } = useReactNative();
	const [profiles, setProfiles] = useState<TProfile[]>();
	const [pendingInvites, setPendingInvites] = useState<TInvite[]>([]);
	const [selectedProfile, setSelectedProfile] = useState<TProfile | undefined>(undefined);
	//Smelly code
	const { onProfileChanged } = usePermissions();
	const navigate = useNavigate();

	const chooseProfile = async (currentProfile: TProfile) => {
		setSelectedProfile(currentProfile);
		await sendMessageToRN('profile-changed', currentProfile);
		onProfileChanged(currentProfile);
		navigate('/home');
	};

	const acceptInviteAndRefresh = async (uuid: string) => {
		await acceptInvite(uuid);
		await setupProfiles();
		await setupInvites();
	};

	useEffect(() => {
		console.log('profiles context init');
		void setupProfiles();
		void setupInvites();
	}, []);

	const setupProfiles = async () => {
		const profiles = await getProfiles();
		setProfiles(profiles);
		void sendMessageToRN('profiles-set', profiles);

		if (profiles.length === 1 && !pendingInvites.length) {
			void chooseProfile(profiles[0]);
		}
	};

	const setupInvites = async () => {
		const pendingInvites = await getPendingInvites();
		setPendingInvites(pendingInvites);
	};

	if (!profiles) {
		return <>{props.fallback}</>;
	}

	return (
		<ProfileContext.Provider
			value={{
				profiles,
				pendingInvites,
				selectedProfile,
				chooseProfile,
				acceptInvite: acceptInviteAndRefresh,
				unselectProfile: () => setSelectedProfile(undefined),
			}}>
			{props.children}
		</ProfileContext.Provider>
	);
};
