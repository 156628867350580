import { Card } from '@/components/card';
import { Heading } from '@gle/base-ui.typography.heading';
import { useNavigate } from 'react-router-dom';
import { EmptyList, TrancheCard } from '../../../components';
import { SeasonSelector } from '../../../components/season/season-selector';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { useSettings } from '../../../context/settings';
import { useTranches } from '../../../context/tranches';
import { sortedTranches } from './utils';

export const Payments = () => {
	const navigate = useNavigate();

	const { orgPerson } = useOrgPerson();
	const { settings } = useSettings();
	const { tranches, selectTranche } = useTranches();

	return (
		<div className="hide-scrollbar relative flex flex-col gap-4">
			<div>
				<div className="tw-items-center flex justify-between">
					<Heading level={3}>Pagamenti</Heading>
					<SeasonSelector />
				</div>

				<div className="mb-5 mt-1">
					{settings.paymentsDescription && (
						<div
							className="html-content-preview text-sm"
							dangerouslySetInnerHTML={{ __html: settings.paymentsDescription }}
						/>
					)}
				</div>
			</div>

			<div className="pb-5">
				{tranches.length ? (
					<Card>
						{sortedTranches(tranches).map(t => (
							<div className="border-b border-solid border-gray-100 p-4" key={t._id}>
								<TrancheCard
									tranche={t}
									athlete={orgPerson}
									onClick={() => {
										selectTranche(t._id);
										navigate(`/tranche-detail`);
									}}
								/>
							</div>
						))}
					</Card>
				) : (
					<EmptyList />
				)}
			</div>
		</div>
	);
};
