import { Avatar, AvatarFallback, AvatarImage } from '@/components/avatar';
import { Card } from '@/components/card';

type UserHeaderProps = {
	firstName: string;
	lastName: string;
	avatarUrl?: string;
	organization: {
		name: string;
		logoUrl?: string;
	};
};

export const UserHeader = (props: UserHeaderProps) => {
	const user = props;

	return (
		<Card className="bg-background flex items-center space-x-4 p-4">
			<div className="relative">
				<Avatar className="h-20 w-20">
					<AvatarImage src={user.avatarUrl} alt={`${user.firstName} ${user.lastName}`} />
					<AvatarFallback>
						{user.firstName[0]}
						{user.lastName[0]}
					</AvatarFallback>
				</Avatar>

				<Avatar className="absolute -bottom-1 -right-1 h-7 w-7 border border-solid border-slate-100 bg-white p-1">
					<AvatarImage src={user.organization.logoUrl} alt={user.organization.name} />
				</Avatar>
			</div>
			<div>
				<h2 className="text-xl font-semibold">
					{user.firstName} {user.lastName}
				</h2>
				<p className="text-muted-foreground text-sm">{user.organization.name}</p>
			</div>
		</Card>
	);
};
