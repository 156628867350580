import { Heading } from '@gle/base-ui.typography.heading';
import { useOrgPerson } from '../../../context/org-person/use-org-person';
import { MembershipCard } from './membership-card';

export const MembershipCardPage = () => {
	const { orgPerson, organization } = useOrgPerson();

	const isDisabled = orgPerson.personMetadata.membershipCard?.isDisabled;

	return (
		<div>
			<div className="mb-5">
				<Heading level={3}>Tessera</Heading>
				{isDisabled && (
					<div className="mt-1 text-sm text-zinc-500">
						<p>Questa tessera è disabilitata</p>
					</div>
				)}
			</div>

			<div className="flex justify-center">
				<MembershipCard
					isDisabled={isDisabled}
					width={305}
					data={{
						memberName: `${orgPerson.name} ${orgPerson.surname}`,
						memberPhoto: orgPerson.personMetadata.avatar,
						memberFiscalCode: orgPerson.fiscalCode,
						clubLogo: organization?.logo_url,
						clubColors: (organization?.colors && Object.values(organization?.colors)) || [],
						clubName: organization?.name || '',
						season: orgPerson.season,
					}}
				/>
			</div>
		</div>
	);
};
