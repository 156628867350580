import client from '../client';
import { TOrgPerson } from './TOrgPerson';

export type MetadataParams = {
	orgPersonId: string;
	season: string;
};

export type Metadata = {
	id?: string;
	orgPersonId?: string;
	person?: TOrgPerson['personMetadata'];
	athlete?: TOrgPerson['metadata']['athlete'];
};

const getMetadata = async (params: MetadataParams) => {
	return await client.get<Metadata>(`org-people/${params.orgPersonId}/metadata`, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

const setMetadata = async (params: MetadataParams, payload: Metadata) => {
	return await client.patch<Metadata>(`org-people/${params.orgPersonId}/metadata`, payload, {
		headers: {
			'x-gle-current-season': params.season,
			'x-gle-selected-season': params.season,
		},
	});
};

export { getMetadata, setMetadata };
