import { Avatar, NameSection } from '../../../../../../components';
import { Flex } from '../../../../../../core/@ui/flex';

export type RenameOrgPersonViewProps = {
	name: string;
	surname: string;
	avatarUrl?: string;
	isEditing: boolean;
	onChangeRenaming: (value: boolean) => void;
	isRenaming: boolean;
};
export const RenameOrgPersonView = (props: RenameOrgPersonViewProps) => {
	return (
		<Flex marginBlock={10} flexDirection="column">
			<Avatar url={props.avatarUrl} />
			<NameSection
				name={props.name}
				surname={props.surname}
				isEditing={props.isEditing}
				onChangeRenaming={(value: boolean) => props.onChangeRenaming(value)}
				isRenaming={props.isRenaming}
			/>
		</Flex>
	);
};
