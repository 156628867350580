import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as semver from 'semver';
import uuid from 'short-uuid';
import { useReactNative } from '../../hooks/use-react-native';

type AuthContextType = {
	auth0UserId: string;
	isLoading: boolean;
	isAuthenticated: boolean;
	login: () => void;
	logout: () => void;
	token: string;
	clientId: string;
	loggingFlowData: {
		isNewFlow: boolean;
		isFromApp: boolean;
		appVersion: string | null;
	};
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthFromWebProvider: React.FC<PropsWithChildren & { fallback: React.ReactNode }> = ({ fallback, ...props }) => {
	const navigate = useNavigate();
	const { sendMessageToRN, appVersion, isFromApp } = useReactNative();

	const {
		isAuthenticated,
		isLoading: isAuth0Loading,
		loginWithRedirect,
		user,
		getAccessTokenSilently,
		logout: auth0Logout,
	} = useAuth0();

	const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

	const login = () => {
		window.location.href = window._env_.REACT_APP_AUTH_APP_URL;
	};
	const logout = () => {
		//Retro-compatibility with version < 1.0.11. Remove this code when all users have updated the app
		if (!appVersion || semver.lte(appVersion, '1.0.11')) {
			void sendMessageToRN('logout', {});
		}
		void auth0Logout({
			logoutParams: {
				returnTo: window._env_.REACT_APP_AUTH_APP_URL,
			},
			clientId: window._env_.REACT_APP_AUTH0_CLIENT_ID,
		});
	};

	const setupAuth = async () => {
		if (!user || !user.sub) {
			return;
		}

		const token = await getAccessTokenSilently();

		try {
			if (!token) {
				throw new Error('token not found');
			}

			setAccessToken(token);
		} catch (err: any) {
			if (err.message === 'role-not-allowed') {
				navigate('/role-not-allowed');
			} else {
				console.error('auth0 login error', err);
				logout();
			}
		}
	};

	const checkAuthStatus = (isAuth: boolean) => {
		if (isAuth) {
			void setupAuth();
			return;
		}

		const queryParams = new URLSearchParams(window.location.search);

		if (queryParams.get('fromAuth')) {
			void loginWithRedirect({
				authorizationParams: {
					redirect_uri: window.location.origin,
				},
			});
			return;
		}

		redirectToAuth(window.location.origin);
	};

	useEffect(() => {
		console.log('auth context init');
		if (!isAuth0Loading) {
			checkAuthStatus(isAuthenticated);
		}
	}, [isAuth0Loading]);

	if (isAuth0Loading || !accessToken || !user || !user.sub) {
		return <>{fallback}</>;
	}

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				auth0UserId: user.sub,
				login,
				logout,
				token: accessToken,
				isLoading: isAuth0Loading,
				clientId: uuid.generate(),
				loggingFlowData: {
					isNewFlow: false,
					isFromApp,
					appVersion,
				},
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};

const AuthFromAppProvider: React.FC<{ fallback: React.ReactNode; children: React.ReactNode }> = ({
	fallback,
	...props
}) => {
	const { sendMessageToRN, appVersion, isFromApp } = useReactNative();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const token = window.localStorage.getItem('accessToken') || undefined;
	const userId = window.localStorage.getItem('userId') || undefined;

	void sendMessageToRN('app-version', { appVersion });

	const login = () => {
		void sendMessageToRN('login', { isFromNewFlow: true });
	};

	const logout = () => {
		void sendMessageToRN('logout', { isFromNewFlow: true });
	};

	if (!token || !userId || isLoading) {
		return <>{fallback}</>;
	}

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: !!token && !!userId,
				auth0UserId: userId,
				login,
				logout,
				token,
				isLoading,
				clientId: uuid.generate(),
				loggingFlowData: {
					isNewFlow: true,
					isFromApp,
					appVersion,
				},
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};

export const AuthProvider: React.FC<{
	fallback: React.ReactNode;
	children: React.ReactNode;
}> = props => {
	const { sendMessageToRN } = useReactNative();
	//retro-compatibility with old app versions. Remove && appVersion && semver.gte(appVersion, '1.1.0') when all users have updated the app
	const shouldUseAuthFromApp = !!window.localStorage.getItem('accessToken');

	if (shouldUseAuthFromApp) {
		return <AuthFromAppProvider fallback={props.fallback}>{props.children}</AuthFromAppProvider>;
	} else {
		return (
			<Auth0Provider
				domain={window._env_.REACT_APP_AUTH0_DOMAIN}
				clientId={window._env_.REACT_APP_AUTH0_CLIENT_ID}
				authorizationParams={{
					redirect_uri: window.location.origin,
					//audience and scope are used to compose the key for the data-token in localstorage
					audience: window._env_.REACT_APP_AUTH0_AUDIENCE,
					scope: window._env_.REACT_APP_AUTH0_SCOPE,
				}}
				cacheLocation="localstorage">
				<AuthFromWebProvider fallback={props.fallback}>{props.children}</AuthFromWebProvider>
			</Auth0Provider>
		);
	}
};

const redirectToAuth = (callbackUrl?: string) => {
	const url = window._env_.REACT_APP_AUTH_APP_URL!;

	let query = '';
	if (callbackUrl) {
		const searchParams = new URLSearchParams({ callbackUrl });
		query = `?${searchParams.toString()}`;
	}

	window.location.href = `${url}${query}`;
	return;
};
