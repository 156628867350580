import { Button } from '@/components/button';
import { Heading } from '@gle/base-ui.typography.heading';
import { useI18n } from '@gle/providers.i18n';
import { TObjTranche } from '@golee/gle-types';
import { ChevronLeftIcon, DownloadIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Currency, getTrancheChip, getTrancheReasonLabel } from '../../../components';
import { getDocuments, TDocument } from '../../../api/documents/entities/documents';
import { useEffect, useState } from 'react';

export const TrancheDetailLayout = ({
	PrimaryCTA,
	TrancheInfo,
	tranche,
}: {
	tranche: TObjTranche;
	PrimaryCTA: React.ReactElement;
	TrancheInfo: React.ReactElement;
}) => {
	const { messages } = useI18n();
	const navigate = useNavigate();
	const [attachedDocuments, setAttachedDocuments] = useState<TDocument[]>([]);

	useEffect(() => {
		getDocuments('operation', tranche._id).then(resp => {
			setAttachedDocuments(resp.data);
		});
	}, []);

	return (
		<div>
			<div style={{ textAlign: 'center' }}>
				{getTrancheChip(tranche, messages)}

				<Heading level={2} style={{ marginBlock: 20 }}>
					{getTrancheReasonLabel(messages, tranche)}
				</Heading>
				<Heading level={1} style={{ fontSize: 40, marginBlock: 20 }}>
					<Currency value={tranche.amount.total_amount} />
				</Heading>

				{PrimaryCTA}
			</div>

			{attachedDocuments.length > 0 && (
				<div
					style={{
						paddingTop: 20,
						marginTop: 20,
						borderTop: '1px solid var(--gray-lightest-color)',
					}}>
					<Heading level={4} style={{ marginBottom: 10 }}>
						{'Documenti allegati'}
					</Heading>

					<div className={'flex flex-wrap gap-2'}>
						{attachedDocuments.map(doc => (
							<a key={doc._id} href={doc.url} target={'_blank'}>
								<Button variant={'secondary'} size={'sm'}>
									{doc.name} <DownloadIcon size={18} className="ml-2" />
								</Button>
							</a>
						))}
					</div>
				</div>
			)}

			<div
				style={{
					paddingTop: 20,
					marginTop: 20,
					borderTop: '1px solid var(--gray-lightest-color)',
				}}>
				<Heading level={4} style={{ marginBottom: 10 }}>
					{messages['details']}
				</Heading>

				{TrancheInfo}
			</div>
			<div className="mt-20 flex justify-center">
				<Button variant="secondary" onClick={() => navigate('/payments')}>
					<ChevronLeftIcon size={18} className="mr-2" /> {messages['back']}
				</Button>
			</div>
		</div>
	);
};
