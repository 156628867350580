export type FlexProps = Pick<
	React.CSSProperties,
	| 'flexDirection'
	| 'gap'
	| 'rowGap'
	| 'columnGap'
	| 'alignItems'
	| 'justifyContent'
	| 'alignContent'
	| 'justifyItems'
	| 'margin'
	| 'marginTop'
	| 'marginBottom'
	| 'marginBlock'
	| 'marginInline'
	| 'padding'
	| 'paddingTop'
	| 'paddingBottom'
	| 'paddingBlock'
	| 'paddingInline'
	| 'paddingLeft'
	| 'paddingRight'
	| 'flexWrap'
	| 'position'
	| 'width'
	| 'height'
	| 'flex'
	| 'flexBasis'
	| 'flexGrow'
	| 'cursor'
	| 'border'
	| 'borderBottom'
	| 'borderTop'
	| 'borderRadius'
	| 'borderLeft'
	| 'borderRight'
	| 'maxWidth'
	| 'background'
> &
	Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'className'> & {
		children: React.ReactNode;
	};

export const Flex: React.FC<FlexProps> = ({ onClick, children, className, ...props }) => {
	return (
		<div
			style={{
				display: 'flex',
				...props,
			}}
			className={className}
			onClick={onClick}>
			{children}
		</div>
	);
};
