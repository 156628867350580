import { Button } from '@/components/button';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@/components/drawer';
import { Input } from '@gle/base-ui.inputs.input';
import moment from 'moment';
import 'moment/locale/it';
import { useEffect, useState } from 'react';
import GoingNotes from './going-notes';
import NotGoingNotes from './not-going-notes';
moment.locale('it');

const NotesModal = ({
	presence,
	...props
}: {
	presence: null | boolean;
	note?: string;
	onSave: (note?: string) => void;
}) => {
	const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);
	const [selectedReason, setSelectedReason] = useState<string | undefined>(undefined);

	const [note, setNote] = useState<string | undefined>(props.note);

	useEffect(() => {
		setNote(props.note);
	}, [props.note]);

	const onSelectReason = (reason?: string) => {
		setNote(reason);
		setSelectedReason(reason);
	};

	const onSave = () => {
		props.onSave(note);
		setIsNoteModalOpen(false);
	};

	return (
		<Drawer open={isNoteModalOpen} onOpenChange={setIsNoteModalOpen}>
			<div className="flex items-center justify-between gap-5">
				<p className="text-sm text-gray-400">Note:</p>
				<Input
					onClick={() => setIsNoteModalOpen(true)}
					value={note || ''}
					inputStyle={{ height: 38, color: '#a9a9a9' }}
				/>
			</div>
			<DrawerContent className="h-full">
				<DrawerHeader>
					<DrawerTitle>{!presence ? 'Non ci sono perchè...' : 'Aggiungi nota'}</DrawerTitle>
				</DrawerHeader>

				<div className="p-4">
					{!presence ? (
						<NotGoingNotes onClick={onSelectReason} selectedReason={selectedReason} />
					) : (
						<GoingNotes onClick={onSelectReason} selectedReason={selectedReason} />
					)}

					<div className="mt-4 flex flex-col gap-2">
						<Input
							value={note || ''}
							onChange={setNote}
							inputStyle={{ height: 38 }}
							placeholder="Oppure scrivi qui..."
						/>
						<Button onClick={onSave}>Salva</Button>
						<Button onClick={() => setIsNoteModalOpen(false)} variant={'secondary'}>
							Annulla
						</Button>
					</div>
				</div>
			</DrawerContent>
		</Drawer>
	);
};

export default NotesModal;
